<template>
  <nav id="sidebar" aria-label="Main Navigation">
    <div class="content-header gap-3 flex-shrink-0">
      <div class="nav-header gap-3 flex-grow-1">
        <img :src="logoBlack" style="height: 2rem" />
        <span class="hr-vert" style="height: 2rem"></span>
        <div class="d-flex flex-column flex-grow-1">
          <p class="m-0 h6">{{ project.active?.name }}</p>
        </div>
      </div>
      <div>
        <button type="button" class="d-lg-none btn btn-light btn-dual btn-sm">
          <i class="material-icons md-18" @click="$emit('close-nav')">close</i>
        </button>
      </div>
    </div>
    <aside class="content-side flex-grow-1 overflow-hidden">
      <ul class="nav-main" data-simplebar>
        <li class="nav-main-item">
          <router-link class="nav-link" :to="{ name: 'dashboard' }">
            <i class="material-icons nav-link-icon">home</i>
            {{ $t('sidebar.menu.dashboard') }}
          </router-link>
        </li>
        <li class="nav-main-item">
          <router-link class="nav-link" :to="{ name: 'points_of_interest' }">
            <i class="material-icons nav-link-icon">location_on</i>
            {{ $t('sidebar.menu.poi') }}
          </router-link>
        </li>
        <li class="nav-main-item">
          <router-link class="nav-link" :to="{ name: 'announcements' }">
            <i class="material-icons nav-link-icon">campaign</i>
            {{ $t('sidebar.menu.announcements') }}
          </router-link>
        </li>
        <li class="nav-main-item">
          <router-link class="nav-link" to="/navigation">
            <i class="material-icons nav-link-icon">share</i>
            {{ $t('sidebar.menu.navigation') }}
          </router-link>
        </li>
        <li class="nav-main-item">
          <router-link class="nav-link" to="/components">
            <i class="material-icons nav-link-icon">folder</i>
            {{ $t('sidebar.menu.pages') }}
          </router-link>
        </li>
        <li class="nav-main-item">
          <router-link class="nav-link" to="/categories">
            <i class="material-icons nav-link-icon">category</i>
            {{ $t('sidebar.menu.categories') }}
          </router-link>
        </li>
        <li class="nav-main-item">
          <router-link class="nav-link" to="/media">
            <i class="material-icons nav-link-icon">collections</i>
            {{ $t('sidebar.menu.media') }}
          </router-link>
        </li>
      </ul>
    </aside>
    <footer class="content-footer flex-shrink-0">
      <ul class="nav-main">
        <li class="nav-main-item">
          <button class="nav-link" @click="resetProject">
            <i class="material-icons nav-link-icon">list</i>
            {{ $t('sidebar.menu.footer.switch') }}
          </button>
        </li>
        <li class="nav-main-item">
          <a href="https://glooh.freshdesk.com/support/home" target="_blank" class="nav-link">
            <i class="material-icons nav-link-icon">contact_support</i>
            {{ $t('sidebar.menu.footer.help') }}
          </a>
        </li>
        <li class="nav-main-item">
          <button class="nav-link text-danger" @click="signOut()">
            <i class="material-icons nav-link-icon">logout</i>
            {{ $t('sidebar.menu.footer.logout') }}
          </button>
        </li>
      </ul>
    </footer>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { onBeforeRouteUpdate, useRouter } from 'vue-router'

import logoBlack from '@avriopolis/common/assets/logo-bk.png'

import Auth from '@/store/auth'

import project, { setProjectId } from '@/store/project'

export default defineComponent({
  name: 'Sidebar',
  emits: ['close-nav'],
  setup(ctx, { emit }) {
    const router = useRouter()

    onBeforeRouteUpdate(() => {
      emit('close-nav')
    })

    function resetProject() {
      setProjectId(-1)
      router.push({ name: 'select-project' })
    }

    return { logoBlack, project, resetProject, signOut: Auth.logout }
  },
})
</script>

<style lang="scss" scoped>
#sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;

  z-index: 1032;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow-y: auto;
  -webkit-transform: translateX(-100%) translateY(0) translateZ(0);
  transform: translateX(-100%) translateY(0) translateZ(0);
  -webkit-overflow-scrolling: touch;
  will-change: transform;

  color: #444;
  background-color: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.05);

  transition: transform 0.28s ease-out;

  .content-header {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.05);
    margin: 0;
  }

  .nav-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
  }

  .nav-main {
    padding-left: 0;
    list-style: none;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    max-height: 100%;
  }

  .nav-main-item {
    display: flex;
    flex-direction: column;
  }

  .nav-link {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0.5rem 1.25rem;
    min-height: 2.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #343a40;
    background-color: transparent;
    text-decoration: none;
    border: none;
    border-left: 0.5rem solid #fff;
    transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
    user-select: none;

    &.disabled {
      opacity: 0.4;
    }
  }

  .nav-link .nav-link-icon {
    flex: 0 0 auto;
    display: inline-block;
    margin-right: 0.625rem;
    min-width: 1rem;
    text-align: center;
  }

  .nav-link.router-link-active {
    background-color: rgb(82, 82, 82);
    background-color: linear-gradient(125deg, rgba(82, 82, 82, 1) 0%, rgba(56, 56, 56, 1) 100%);
    color: white;
    border-color: #0d6efd;
  }

  .content-footer .nav-main {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  #sidebar {
    width: 240px;
    transform: translateX(0) translateY(0) translateZ(0);
  }
}

.navOpen #sidebar {
  transform: translateX(0) translateY(0) translateZ(0);
}
</style>
