<template>
  <div class="d-flex flex-column flex-grow-1 position-relative">
    <PageHeader>
      <template #default>Menu Management</template>
    </PageHeader>
    <transition appear name="fade" mode="out-in">
      <div class="d-flex flex-grow-1 gap-4 p-4">
        <div class="menu-selector d-flex flex-column p-4 gap-2">
          <h4>Select Menu</h4>
          <div class="menus d-flex gap-1 flex-grow-1 flex-column justify-content-start align-items-start">
            <div class="menu-wrapper w-100">
              <div class="menu">
                <div class="items nav">
                  <div
                    class="nav-link w-100 d-flex justify-content-between"
                    v-for="menuItem in parents"
                    :key="menuItem[1]"
                    :class="{ active: menuItem[1] === menuIndex }"
                    @click.self="setParent(menuItem[1])"
                  >
                    {{ menuItem[0] }}
                    <button class="btn btn-sm m-0 p-0" @click="editParent(menuItem[1])">
                      <span class="material-icons">edit</span>
                    </button>
                  </div>
                  <button
                    class="btn w-100 btn-sm btn-outline-primary d-flex text-nowrap mt-2"
                    @click="() => createMenu()"
                  >
                    <span class="material-icons me-2">add</span>
                    Create Menu
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="visibleMenus.length > 0" class="navigation d-flex flex-grow-1 p-4 gap-2">
          <div class="menus d-flex gap-1 flex-grow-1 flex-column justify-content-start align-items-start">
            <button
              class="btn btn-sm btn-outline-secondary"
              :disabled="visibleMenus[0] === null"
              @click="setMenu(visibleMenus[0])"
            >
              <span class="material-icons">chevron_left</span>
            </button>
            <div v-if="visibleMenus?.length > 0" class="menu-wrapper w-100">
              <div v-for="menu of visibleMenus" :key="menu" :data-menu="menu" class="menu">
                <div class="items nav">
                  <div
                    class="nav-link w-100 d-flex justify-content-between"
                    v-for="menuItem in getChildren(menu)"
                    :key="menuItem.id"
                    :class="{ active: menuItem.id === activeMenu }"
                    @click.self="setMenu(menuItem.id)"
                  >
                    <localised-value :value="menuItem.title" v-slot="{ value }">
                      {{ value }} ({{ menuItem.id }})
                    </localised-value>

                    <div class="d-flex justify-content-center align-items-center gap-3">
                      <button
                        v-if="menuItem.target_type === 'none'"
                        class="btn btn-sm m-0 p-0"
                        @click="createMenu(menuItem.id)"
                      >
                        <span class="material-icons">add</span>
                      </button>

                      <button
                        v-if="getChildren(menuItem.id).length > 0"
                        class="btn btn-sm m-0 p-0"
                        @click="editParent(menuItem.id)"
                      >
                        <span class="material-icons">edit</span>
                      </button>

                      <button
                        v-if="getChildren(menuItem.id).length === 0"
                        class="btn btn-sm m-0 p-0"
                        @click="deleteMenu(menuItem.id)"
                      >
                        <span class="material-icons">delete</span>
                      </button>
                      <span @click="setMenu(menuItem.id)" v-else class="material-icons">navigate_next</span>
                    </div>
                  </div>
                  <button
                    class="btn w-100 btn-sm btn-outline-primary d-flex text-nowrap mt-2"
                    @click="() => createMenuItem()"
                  >
                    <span class="material-icons me-2">add</span>
                    Create Menu Item
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div ref="offcanvasRef" class="offcanvas offcanvas-end" :tabindex="-1" id="offcanvas">
      <div class="position-absolute top-0 end-0 p-3">
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <Menu v-if="activeMenu" :key="activeMenu" :id="activeMenu" :hasChildren="hasChildren" @update="menuUpdated" />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import axios from '@/api'

import LocalisedValue from '@/components/misc/LocalisedValue.vue'
import Menu from '@/components/misc/Menu.vue'
import { PageHeader } from '@avriopolis/common/components'
import project from '@/store/project'
import { Offcanvas } from 'bootstrap'
import Swal from 'sweetalert2'
import { slugify } from '@/utils/misc'
import { hasChanges, processChanges } from '@/store/changes'

export default defineComponent({
  name: 'Navigation',
  emits: ['loading'],
  components: {
    PageHeader,
    LocalisedValue,
    Menu,
  },
  setup(props, { emit }) {
    const menus = ref<Menu[]>()
    const parents = ref<[String, Number][]>([])
    const menuIndex = ref<number | null>(null)
    const activeMenu = ref<number | null>(null)
    const i18n = useI18n()

    const offcanvasRef = ref(),
      offcanvas = ref()

    async function fetchData() {
      const response = await axios.get(
        `/items/menus?filter[projects_id]=${project.value?.active?.id}&filter[status][_neq]=archived`,
      )
      menus.value = response?.data?.data
      parents.value = menus.value?.filter((c) => !c.parent_id).map((c) => [c.key, c.id])
      //menuIndex.value = menus.value?.find((m) => m.key === 'main-navigation')?.id ?? -1
    }

    async function updateData() {
      emit('loading', true)
      try {
        menus.value = []
        parents.value = []
        await fetchData()
      } catch (e) {}
      emit('loading', false)
    }

    const visibleMenus = computed(() => {
      if (!menuIndex.value) return []
      const parent = menus.value?.find((m) => m?.id === menuIndex.value)?.parent_id ?? null
      const children = menus.value?.filter((m) => m?.parent_id === menuIndex.value).map((m) => m?.id) ?? []
      return [parent, menuIndex.value, ...children]
    })

    const hasChildren = computed(() => {
      if (activeMenu.value) {
        return menus.value?.filter((c) => c.parent_id == activeMenu.value).length > 0
      }
      return false
    })

    function getChildren(id: number | null) {
      const menuItems = menus.value?.filter((m) => m?.parent_id === id) ?? []
      menuItems.sort((a, b) => a.child_index - b.child_index)
      return menuItems
    }

    function setMenu(id: number | null) {
      if (typeof id === 'number' && id >= 0) {
        if (getChildren(id).length > 0) {
          menuIndex.value = id
        } else {
          activeMenu.value = id
          offcanvas.value.show()
        }
      }
    }

    function setParent(id: number | null) {
      menuIndex.value = id
    }

    function editParent(id: number | null) {
      activeMenu.value = id
      offcanvas.value.show()
    }

    async function deleteMenu(id: number) {
      await axios.patch(`items/menus/${id}`, {
        status: 'archived',
      })
      updateData()
    }

    function createMenuItem() {
      createMenu(menuIndex.value)
    }

    function menuUpdated() {
      updateData()
      offcanvas.value?.hide()
      activeMenu.value = null
    }

    async function createMenu(parent_id?: number) {
      const result = await Swal.fire({
        input: 'text',
        title: `Menu Text`,
        inputPlaceholder: 'Text',
        showCancelButton: true,
        confirmButtonText: 'Create',
        confirmButtonColor: '#0d6efd',
      })

      if (result.isConfirmed) {
        if (!result.value) {
          Swal.fire('Invalid', 'Text must be entered', 'error')
        } else {
          await axios.post('items/menus', {
            title: [{ lang: i18n.locale.value, value: result.value }],
            key: slugify(result.value),
            projects_id: project.value.active?.id,
            status: 'published',
            parent_id,
            target_type: 'none',
          })

          updateData()
        }
      }
    }

    updateData()

    onMounted(() => {
      if (offcanvasRef.value) {
        offcanvas.value = new Offcanvas(offcanvasRef.value)
        offcanvasRef.value.addEventListener('hide.bs.offcanvas', async (e: any) => {
          if (hasChanges()) {
            e.preventDefault()
            if (await processChanges(false)) {
              updateData()
              offcanvas.value.hide()
              activeMenu.value = null
            }
          }
        })
      }
    })

    return {
      menuIndex,
      activeMenu,
      visibleMenus,
      menus,
      getChildren,
      deleteMenu,
      setMenu,
      offcanvasRef,
      parents,
      setParent,
      createMenu,
      createMenuItem,
      editParent,
      hasChildren,
      menuUpdated,
    }
  },
})
</script>

<style lang="scss" scoped>
.navigation,
.menu-selector {
  background: white;

  .menus > .btn:disabled {
    opacity: 0.2;
  }

  .menu-wrapper {
    position: relative;
    overflow: hidden;
    height: 100%;

    .menu .items {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      gap: 0.25rem;
      overflow: auto;
      flex: 1;

      .nav-link {
        cursor: pointer;
        background: #f8f9fa !important;
        border-left: 5px solid #525252;
        color: #888;

        &.active {
          border-color: #0d6efd;
          color: #0d6efd;
        }
      }
    }
  }
}

.menu-selector {
  min-width: 300px;
}

.navigation {
  .menu {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5px;
    display: flex;
    flex-direction: column;
    flex-wrap: none;
    overflow: hidden;

    transition: all 0.5s ease-in-out;

    &:nth-child(1) {
      transform: scale(1) translate(-100%, 0);
    }

    &:not(:nth-child(1)):not(:nth-child(2)) {
      transform: scale(1) translate(100%, 0);
    }
  }
}
</style>
