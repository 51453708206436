<template>
  <div v-if="announcement" class="d-flex flex-column flex-grow-1 position-relative">
    <PageHeader>
      <template #default><localised-value :value="announcement?.title" /></template>
      <template #message>Announcement</template>
      <template #right>
        <ul class="nav nav-pills justify-content-center">
          <li class="nav-item">
            <a class="nav-link disabled">Display</a>
          </li>
          <div class="d-flex flex-row">
            <li class="nav-item">
              <router-link class="nav-link d-flex" active-class="active" :to="{ params: { tab: 'info' } }">
                Info
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link d-flex" active-class="active" :to="{ params: { tab: 'media' } }">
                Media
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link d-flex" active-class="active" :to="{ params: { tab: 'targeting' } }">
                Targeting
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link d-flex" active-class="active" :to="{ params: { tab: 'availability' } }">
                Availability
              </router-link>
            </li>
            <li class="nav-item" v-if="auth?.user?.value?.role?.admin_access">
              <router-link class="nav-link d-flex" active-class="active" :to="{ params: { tab: 'templates' } }">
                Templates
              </router-link>
            </li>
          </div>
        </ul>
      </template>
    </PageHeader>
    <transition appear name="fade" mode="out-in">
      <div
        v-if="$route.params.tab === 'info'"
        class="content d-flex flex-column flex-xl-row-reverse flex-grow-1 position-relative gap-3 align-items-xl-start"
      >
        <div style="min-width: 290px; top: 1rem" class="sticky-xl-top">
          <div class="d-flex flex-column p-3 border rounded-3 bg-white mb-2">
            <properties
              :object="{
                Name: announcement?.title,
                Type: announcement?.type,
                Status: announcement?.status,
                Source: announcement?.source,
                'Date Created': new Date(announcement?.date_created).toLocaleString(),
                'Last Updated': new Date(announcement?.date_updated).toLocaleString(),
                Categories: announcement?.categories?.map((c) => c.categories_id.name),
                Tags: announcement?.tags,
              }"
            />
          </div>
          <div class="d-flex justify-content-end gap-2">
            <router-link class="btn btn-secondary btn-sm d-flex" :to="{ name: 'edit-announcements' }">
              <span class="material-icons me-2">edit</span>
              <span class="fs-6">Edit</span>
            </router-link>
            <router-link class="btn btn-danger btn-sm d-flex" :to="{ name: 'edit-announcements' }">
              <span class="material-icons me-2">archive</span>
              <span class="fs-6">Archive</span>
            </router-link>
          </div>
        </div>

        <fields-view :fields="announcement?.fields" />
      </div>
      <div class="content" v-else-if="$route.params.tab === 'media'">
        <media-viewer :query="{ filter: { announcements_id: announcement?.id } }" @loading="$emit('loading', $event)" />
      </div>
      <div class="content flex-grow-1" v-else-if="$route.params.tab === 'targeting'">
        <announcement-targeting :announcement="announcement" @loading="$emit('loading', $event)" />
      </div>
      <div class="content flex-grow-1" v-else-if="$route.params.tab === 'availability'">
        <availability type="Announcements" :availability="announcement" @loading="$emit('loading', $event)" />
      </div>
      <div class="content flex-grow-1" v-else-if="$route.params.tab === 'templates'">
        <TemplatePicker :briefId="announcement?.id" />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, onUpdated, ref, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import axios from '@/api'
import auth from '@/store/auth'

import { PageHeader } from '@avriopolis/common/components'
import LocalisedValue from '@/components/misc/LocalisedValue.vue'
import FieldsView from '@/components/fields/FieldsView.vue'
import Properties from '@/components/misc/Properties.vue'
import MediaViewer from '@/components/media/MediaViewer.vue'
import AnnouncementTargeting from '@/components/misc/AnnouncementTargeting.vue'
import Availability from '@/components/misc/Availability.vue'
import TemplatePicker from '@/components/misc/TemplatePicker.vue'

export default defineComponent({
  name: 'View_Announcement',
  emits: ['loading'],
  components: {
    PageHeader,
    LocalisedValue,
    FieldsView,
    Properties,
    MediaViewer,
    AnnouncementTargeting,
    Availability,
    TemplatePicker
  },
  setup(props, { emit }) {
    const route = useRoute()
    const announcement = ref<Announcement>()

    const fetchData = async () => {
      const response = await axios.get(
        `/items/announcements/${route.params.id}?fields=*,fields.*,fields.fields_id.*,kiosks.*,exceptions.*`,
      )
      return response?.data?.data
    }

    async function updateData(loading: boolean = true) {
      loading && emit('loading', true)
      let ann = announcement.value
      try {
        ann = await fetchData()
      } catch (e) {}
      loading && emit('loading', false)
      announcement.value = ann
    }

    updateData()

    return { announcement, updateData, auth }
  },
  beforeRouteUpdate(to, from) {
    if (to.params.tab && from.params.tab) {
      if (to.params.tab === 'info' && from.params.tab !== 'info') this.updateData(false)
    }
  },
  beforeRouteEnter(to, _from, next) {
    if (!to.params.tab) {
      return next({ ...to, params: { ...to.params, tab: 'info' } })
    }
    next()
  },
})
</script>
