<template>
  <div class="d-flex flex-column flex-grow-1 gap-2 mb-5 border rounded-3 bg-white">
    <div v-for="(field, i) in fields" :key="i" class="group d-flex flex-column px-3">
      <h6 class="border-bottom py-2 m-0 text-capitalize">{{ field.key }}</h6>
      <template v-if="field.meta.type === 'csv'">
        <div class="bg-light border p-2 mb-3">
          <span v-for="v of field.value" :key="v" class="badge bg-primary">
            {{ v }}
          </span>
        </div>
      </template>
      <template v-else-if="field.meta.type === 'media' || field.meta.type === 'medias'">
        <p class="bg-light border p-2 mb-3">Please see the media tab to view attached media</p>
      </template>
      <template v-else>
        <localised-value :value="field.value" v-slot="{ value }">
          <p class="bg-light border p-2 mb-3" v-html="value"></p>
        </localised-value>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import WYSIWYG from '@/components/inputs/WYSIWYG.vue'
import TextInput from '@/components/inputs/TextInput.vue'
import LocalisedValue from '@/components/misc/LocalisedValue.vue'

export default defineComponent({
  name: 'MetadataView',
  props: {
    template: {
      type: Object as PropType<{ [key: string]: any }>,
      required: true,
    },
    values: {
      type: Object as PropType<{ [key: string]: any }>,
      required: true,
    },
  },
  components: {
    WYSIWYG,
    TextInput,
    LocalisedValue,
  },
  setup(props) {
    const fields = computed(() => {
      const metadata = props.template
      return Object.keys(metadata).map((k) => ({
        key: k,
        meta: metadata[k],
        value: props.values[k],
      }))
    })

    return { fields }
  },
})
</script>
