<template>
  <header id="page-header">
    <div class="content-header">
      <div class="d-flex align-items-center">
        <button type="button" class="me-2 d-lg-none btn btn-light btn-dual btn-sm" @click="$emit('open-nav')">
          <i class="material-icons md-18">menu</i>
        </button>
        <button type="button" class="d-sm-none btn btn-light btn-dual btn-sm">
          <i class="material-icons md-18">search</i>
        </button>
        <form @submit.prevent="doSearch" class="d-none d-sm-inline-block">
          <div role="group" class="input-group input-group-sm border rounded-3">
            <input
              ref="query"
              type="text"
              :placeholder="$t('header.search.placeholder')"
              class="form-control-alt form-control"
            />
            <div class="input-group-append">
              <span class="input-group-text bg-light border-0">
                <i class="material-icons md-18">search</i>
              </span>
            </div>
          </div>
        </form>
      </div>
      <div class="d-flex align-items-center">
        <language-selector class="me-4" :locale="$i18n.locale" @locale="$i18n.locale = $event" />

        <div class="dropdown">
          <button
            class="btn btn-sm btn-light border dropdown-toggle"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              v-if="user?.avatar"
              :src="`${mediaBase}/assets/${user?.avatar}?key=thumb`"
              class="rounded-circle me-2"
              style="width: 21px"
            />
            <i v-else class="material-icons me-2" style="color: #0d6efd">account_circle</i>
            <span class="d-none d-sm-inline-block me-2">{{ user?.full_name }}</span>
          </button>
          <ul class="dropdown-menu p-0" aria-labelledby="dropdownMenuButton1" style="min-width: 200px">
            <li>
              <div class="p-3 text-center bg-primary rounded-top">
                <img
                  v-if="user?.avatar"
                  :src="`${mediaBase}/assets/${user?.avatar}?key=thumb`"
                  class="img-avatar img-avatar-72 img-avatar-thumb"
                />
                <i
                  v-else
                  class="material-icons img-avatar img-avatar-72 img-avatar-thumb md-72"
                  style="color: rgb(255 255 255 / 30%)"
                >
                  account_circle
                </i>
                <p class="mt-2 mb-0 text-white font-w500">{{ user?.full_name }}</p>
                <p class="mb-0 text-white-50 font-size-sm">{{ user?.role?.name }}</p>
              </div>
              <div class="p-2">
                <!--<a
                  href="javascript:void(0)"
                  class="dropdown-item d-flex align-items-center justify-content-between disabled"
                >
                  <span class="font-size-sm font-w500">Inbox</span>
                  <span class="badge rounded-pill bg-primary ms-2">0</span>
                </a>
                <a href="#/backend/pages/generic/profile" class="dropdown-item disabled">
                  <span class="font-size-sm font-w500">Profile</span>
                </a>
                <a href="javascript:void(0)" class="dropdown-item disabled">
                  <span class="font-size-sm font-w500">Settings</span>
                </a>
                <div role="separator" class="dropdown-divider"></div>
                <a href="#/auth/lock" class="dropdown-item disabled">
                  <span class="font-size-sm font-w500">Lock Account</span>
                </a>-->
                <div role="separator" class="dropdown-divider"></div>
                <a href="javascript:void(0)" class="dropdown-item" @click="doSignOut">
                  <span class="font-size-sm font-w500">{{ $t('header.menu.logout') }}</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import Swal from 'sweetalert2'

import Auth from '@/store/auth'
import LanguageSelector from '@/components/inputs/LanguageSelector.vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'Header',
  emits: ['open-nav'],
  components: { LanguageSelector },
  setup() {
    const router = useRouter()
    const query = ref()

    function doSignOut() {
      Swal.fire({
        title: `Are you sure you want to sign out`,
        icon: 'warning',
        confirmButtonColor: '#0d6efd',
        showDenyButton: true,
      }).then((result: any) => {
        if (result.isConfirmed) {
          Auth.logout()
        }
      })
    }

    function doSearch() {
      router.push({ name: 'search', params: { query: query.value.value } })
    }

    return { user: Auth.user!, query, doSignOut, doSearch, mediaBase: import.meta.env.VITE_API_URL }
  },
})
</script>

<style lang="scss" scoped>
#page-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  z-index: 1030;
  background-color: #fff;
  box-shadow: 0 0.125rem 0.375rem rgb(0 0 0 / 5%);

  .content-header {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .content,
  .content-header {
    max-width: 1920px;
  }
}

@media (min-width: 992px) {
  #page-header {
    padding-left: 240px;
  }
}

@media (min-width: 768px) {
  #page-header .content-header {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
}

@media (min-width: 1200px) {
  #page-container {
    .content,
    .content-header {
      width: 92%;
    }
  }
}
</style>
