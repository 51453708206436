<template>
  <template v-if="modal">
    <div
      class="modal fade"
      id="upload"
      tabindex="-1"
      style="z-index: 1200"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      ref="uploadModal"
    >
      <div class="file-upload modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Upload File{{ single ? '' : 's' }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div
              class="dragbox h-100 d-flex flex-column justify-content-center align-items-center"
              @dragover.prevent="() => {}"
              @dragenter.prevent="() => {}"
              @drop.prevent="addFiles($event.dataTransfer.files)"
            >
              <div class="d-flex flex-column gap-4 flex-sm-row w-100 align-items-center align-items-sm-start">
                <div
                  class="d-flex flex-column align-items-center justify-content-center flex-grow-1 border border-2 p-3"
                  style="border-style: dashed !important"
                >
                  <p class="mb-2">Drop file{{ single ? '' : 's' }} here to upload</p>
                  <small>or</small>
                  <button type="button" class="btn btn-outline-primary mt-2" @click="selectFiles">
                    Select File{{ single ? '' : 's' }}
                  </button>
                  <small class="mt-4 text-secondary">Maximum upload size: 20MB</small>
                </div>
                <div
                  v-if="files.length > 0 && !single"
                  class="files d-flex gap-2 flex-column align-items-center justify-content-start"
                >
                  <div
                    v-for="(file, i) in files"
                    :key="i"
                    class="file d-flex align-items-center gap-4 w-100 overflow-hidden"
                  >
                    <span class="text-truncate flex-grow-1">{{ file.name }}</span>
                    <button class="btn btn-sm btn-danger p-0" @click="files.splice(i, 1)">
                      <span class="material-icons md-18">remove</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <input
              ref="fileupload"
              style="display: none"
              type="file"
              id="fileInput"
              @change="addFiles($event.target.files)"
              multiple
            />
            <Spinner v-if="uploading" fill_parent />
          </div>
          <div v-if="!single" class="modal-footer">
            <button type="button" class="btn btn-primary" :class="{ disabled: files.length <= 0 }" @click="uploadFiles">
              Upload
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div
      class="dragbox h-100 d-flex flex-column justify-content-center align-items-center"
      @dragover.prevent="() => {}"
      @dragenter.prevent="() => {}"
      @drop.prevent="addFiles($event.dataTransfer.files)"
    >
      <div class="d-flex h-100 flex-column gap-4 flex-sm-row w-100 align-items-center align-items-sm-start">
        <div
          class="d-flex h-100 flex-column align-items-center justify-content-center flex-grow-1 border border-2 p-3"
          style="border-style: dashed !important"
        >
          <button type="button" class="btn btn-outline-primary mt-2" @click="selectFiles">
            Select File{{ single ? '' : 's' }}
          </button>
          <small class="mt-4 text-secondary">Maximum upload size: 20MB</small>
        </div>
        <div
          v-if="files.length > 0 && !single"
          class="files d-flex gap-2 flex-column align-items-center justify-content-start"
        >
          <div v-for="(file, i) in files" :key="i" class="file d-flex align-items-center gap-4 w-100 overflow-hidden">
            <span class="text-truncate flex-grow-1">{{ file.name }}</span>
            <button class="btn btn-sm btn-danger p-0" @click="files.splice(i, 1)">
              <span class="material-icons md-18">remove</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <input
      ref="fileupload"
      style="display: none"
      type="file"
      id="fileInput"
      @change="addFiles($event.target.files)"
      multiple
    />
    <Spinner v-if="uploading" fill_parent />
  </template>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, ref } from 'vue'
import Swal from 'sweetalert2'
import axios from '@/api'

import { Modal } from 'bootstrap'

import { Spinner } from '@avriopolis/common/components'

export default defineComponent({
  name: 'MediaUploader',
  emits: ['uploaded', 'mediaCreated'],
  props: {
    options: {
      type: Object,
      required: true,
    },
    single: {
      type: Boolean,
      default: false,
    },
    createMedia: {
      type: Boolean,
      default: true,
    },
    mediaId: {
      type: Number,
    },
    modal: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Spinner,
  },
  setup(props, { emit }) {
    const uploadModal = ref()
    const fileupload = ref()
    const uploading = ref(false)
    const files = ref<File[]>([])

    function addFiles(_files: FileList) {
      for (let i = 0; i < _files.length; i++) {
        try {
          const file = _files.item(i)
          if (file) {
            if (files.value.find((f) => f.name === file.name && f.size === file.size)) {
              Swal.fire({
                title: `You have already selected this file for upload`,
                icon: 'warning',
                confirmButtonColor: '#0d6efd',
              })
              continue
            }

            files.value.push(file)
            if (props.single) {
              return uploadFiles()
            }
          }
        } catch (e) {}
      }

      fileupload.value.value = null
    }

    function selectFiles() {
      if (fileupload.value) {
        fileupload.value.click()
      }
    }

    async function uploadRawFiles() {
      const uploaded = []
      for (let file of files.value) {
        const formData = new FormData()
        formData.append('file', file)
        const response = await axios.post('/files', formData)
        if (response.status === 200 && response.data?.data) {
          uploaded.push(response?.data?.data)
        }
      }
      if (uploaded.length > 0) {
        emit('uploaded', uploaded)
      }
      return uploaded
    }

    async function createMediaRecords(files: DirectusFile[]): Promise<Media[]> {
      const uploaded = []
      for (let file of files) {
        let response
        if (props.mediaId && props.single) {
          response = await axios.patch(`/items/media/${props.mediaId}`, {
            directus_files_id: file.id,
          })
        } else {
          response = await axios.post('/items/media', {
            status: 'draft',
            directus_files_id: file.id,
            types: [],
            tags: [],
            locked: false,
            ...props.options,
          })
        }

        if (response.status === 200 && response.data?.data) {
          uploaded.push(response?.data?.data)
        }
      }
      if (uploaded.length > 0) {
        emit('mediaCreated', uploaded)
      }
      return uploaded
    }

    async function uploadFiles() {
      uploading.value = true
      const rawFiles = await uploadRawFiles()
      if (props.createMedia) {
        await createMediaRecords(rawFiles)
      }
      uploading.value = false

      const modal = Modal.getInstance(uploadModal.value)
      modal?.hide()
      modal?.dispose()
    }

    return { uploadModal, fileupload, uploading, selectFiles, addFiles, files, uploadFiles }
  },
})
</script>

<style lang="scss" scoped>
.dragbox {
  user-select: none;
  * {
    pointer-events: none;
  }
  a,
  button {
    pointer-events: all;
  }
}
.file-upload {
  .files {
    max-width: 80%;
  }
}
@media screen and (min-width: 576px) {
  .file-upload {
    max-width: 80%;
    .files {
      max-width: 40%;
    }
  }
}
@media screen and (min-width: 750px) {
  .file-upload {
    max-width: 700px;
  }
}
</style>
