import { reactive } from 'vue'
import { NavigationGuard } from 'vue-router'
import Auth from '@/store/auth'

const THROTTLER_TIME = 500
const WARNING_THRESHOLD = 20000
const INACTIVE_THRESHOLD = 600000

type TimeoutState = {
  activated: boolean
  isInactive: boolean
  throttlerTimeout?: number
  timeout?: number
  warning?: number
  displayWarning: boolean
}

const state = reactive<TimeoutState>({
  activated: false,
  isInactive: false,
  throttlerTimeout: undefined,
  timeout: undefined,
  warning: undefined,
  displayWarning: false,
})

export function activateActivityTracker() {
  if (!state.activated) {
    window.addEventListener('mousedown', userActivityThrottler)
    window.addEventListener('keydown', userActivityThrottler)
    state.activated = true
  }
}

export async function userTimedOut() {
  state.activated = false
  if (Auth.user.value) {
    Auth.logout()
  }
}

export function resetUserActivityTimeout() {
  if (!state.activated) activateActivityTracker()
  if (state.timeout !== undefined) clearTimeout(state.timeout)
  if (state.warning !== undefined) clearTimeout(state.warning)

  if (Auth.user.value && Auth.token.value) {
    state.displayWarning = false
    state.timeout = window.setTimeout(userTimedOut, INACTIVE_THRESHOLD)
    state.warning = window.setTimeout(() => (state.displayWarning = true), INACTIVE_THRESHOLD - WARNING_THRESHOLD)
  }
}

export function userActivityThrottler() {
  if (state.throttlerTimeout === undefined) {
    state.throttlerTimeout = window.setTimeout(() => {
      resetUserActivityTimeout()
      clearTimeout(state.throttlerTimeout)
      state.throttlerTimeout = undefined
    }, THROTTLER_TIME)
  }
}

export const routeGuard: NavigationGuard = (_to, _from, next) => {
  userActivityThrottler()
  next()
}

export default state
