<template>
  <centered-form>
    <a :href="`${apiUrl}/auth/login/glooh?redirect=${location.origin}/project`" class="btn btn-block btn-primary w-100">
      Click here to sign in
    </a>
    <a :href="`${apiUrl}/auth/login/monaco?redirect=${location.origin}/project`" class="btn btn-block btn-danger w-100 mt-4">
      Click here to sign in with Monaco
    </a>
  </centered-form>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { CenteredForm } from '@avriopolis/common/components'

export default defineComponent({
  name: 'LoginPage',
  components: {
    CenteredForm,
  },
  setup() {
    return { apiUrl: import.meta.env.VITE_API_URL, location: window.location }
  },
})
</script>
