<template>
  <div class="d-flex flex-column py-2">
    <input
      v-model="newValue"
      class="form-control"
      type="text"
      :placeholder="`Enter new ${type}`"
      @keyup.enter="addValue"
    />
    <div class="d-flex flex-wrap mt-2 gap-1 align-items-center justify-content-start">
      <button v-if="localValues?.length === 0" class="btn me-2 btn-sm disabled">No {{ type }}'s found</button>
      <button v-for="(val, i) in localValues" :key="i" class="btn btn-sm btn-primary" @click="removeValue(val)">
        {{ val }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, PropType, watchEffect } from 'vue'

export default defineComponent({
  name: 'MediaViewer',
  props: {
    type: {
      type: String,
      required: true,
    },
    values: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const newValue = ref('')
    const localValues = ref(props.values)

    const addValue = () => {
      const newValues = [...new Set([...localValues.value, newValue.value])]
      localValues.value = [...newValues]
      emit('input', newValues)
      newValue.value = ''
    }

    const removeValue = (val: string) => {
      const newValues = [...localValues.value]
      newValues.splice(newValues.indexOf(val), 1)
      localValues.value = [...newValues]
      emit('input', newValues)
      newValue.value = ''
    }

    watchEffect(() => {
      if (props.values) {
        localValues.value = [...props.values]
      }
    })

    return { newValue, addValue, removeValue, localValues }
  },
})
</script>
