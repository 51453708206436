export default class NestedCheckboxes {
  checked: number[] = []
  indeterminate: number[] = []
  values: Nested[] = []

  constructor(values: Nested[], checked: number[] = []) {
    this.values = values
    this.checked = checked
    checked.forEach((c) => this.recomputeParents(c))
  }

  setSelected(id?: number, state?: boolean) {
    if (id === undefined || id === null) return []

    const categories = this.values ?? []
    const category = categories.find((c) => c.id === id)
    if (!category || (state && this.isSelected(id))) return

    this.deactivateParent(id)
    this.setInCollection(this.checked, id, state)
    this.setInCollection(this.indeterminate, id, false)
    this.deactivateChildren(id)
    this.recomputeParents(id)
  }

  isSelected(id?: number): boolean {
    if (id === undefined || id === null) return false
    if (this.checked.includes(id)) return true

    const category = this.values.find((c) => c.id === id)
    if (category?.parent_id && this.checked.includes(category?.parent_id)) return true
    return this.isSelected(category?.parent_id)
  }

  deactivateParent(id?: number) {
    const parents = this.getParents(id)
    for (let id of parents) {
      if (this.isSelected(id)) {
        this.setInCollection(this.checked, id, false)
        const children = this.getChildren(id, false)
        children.forEach((c) => {
          this.setInCollection(this.checked, c.id, !parents.includes(c.id))
        })
      }
    }
  }

  deactivateChildren(id?: number) {
    this.getChildren(id, false).forEach((c) => {
      this.setInCollection(this.checked, c.id, false)
      this.setInCollection(this.indeterminate, c.id, false)
      this.deactivateChildren(c.id)
    })
  }

  setInCollection(collection: number[], id?: number, state?: boolean) {
    if (id === undefined || id === null) return

    if (state) {
      if (!collection.includes(id)) {
        collection.push(id)
      }
      return true
    } else {
      if (collection.includes(id)) {
        collection.splice(collection.indexOf(id), 1)
      }
      return false
    }
  }

  getChildren(parent_id?: number, activeOnly: boolean = true) {
    if (parent_id === undefined || parent_id === null) return []
    return this.values.filter(
      ({ id, parent_id: pid }) => pid === parent_id && (!activeOnly || this.checked.includes(id)),
    )
  }

  isChecked(id?: number) {
    return !!(id !== undefined && id !== null && this.checked.includes(id))
  }

  isIndeterminate(id?: number) {
    return !!(id !== undefined && id !== null && this.indeterminate.includes(id))
  }

  recomputeParents(id?: number) {
    const parents = this.getParents(id)
    for (let id of parents) {
      const children = this.getChildren(id, false)
      const activeChildren = this.getChildren(id)
      const anyChildren = children.filter((c) => this.isChecked(c.id) || this.isIndeterminate(c.id))
      if (activeChildren.length === children.length) {
        this.setInCollection(this.indeterminate, id, false)
        this.setSelected(id, true)
      } else {
        this.setInCollection(this.indeterminate, id, anyChildren.length > 0)
      }
    }
  }

  getParents(id?: number): Array<number> {
    if (id === undefined || id === null) return []
    const category = this.values.find((c) => c.id === id)
    if (!category || !category.parent_id) return []
    return [...new Set([category.parent_id, ...this.getParents(category.parent_id)].flat())]
  }
}
