<template>
  <template v-for="(prop, i) in Object.keys(object)" :key="i">
    <localised-value v-if="isPropValid(prop)" :value="object[prop]" v-slot="{ value }">
      <template v-if="Array.isArray(value)">
        <div class="d-flex justify-content-between align-items-start gap-3">
          <span class="fs-7 fw-bolder text-muted flex-basis-50 flex-grow-1">{{ prop }}</span>
          <div class="d-flex flex-column flex-grow-1">
            <localised-value v-for="(val, i) in value" :key="i" :value="val" v-slot="{ value }">
              <template v-if="typeof value === 'object'">
                <properties :object="value" nested />
              </template>
              <template v-else>
                <span class="fs-7 text-end">
                  <span class="badge bg-primary">{{ value }}</span>
                </span>
              </template>
            </localised-value>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="d-flex justify-content-between align-items-center gap-5">
          <span class="fs-7 fw-bolder text-muted">{{ prop }}</span>
          <span class="fs-7 text-end">{{ value }}</span>
        </div>
      </template>
    </localised-value>
    <hr v-if="!nested && isPropValid(prop) && i < Object.keys(object).length - 1" />
  </template>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import LocalisedValue from '@/components/misc/LocalisedValue.vue'

export default defineComponent({
  name: 'Properties',
  props: {
    object: {
      type: Object,
      default: {},
    },
    nested: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LocalisedValue,
  },
  setup(props) {
    function isPropValid(prop: string) {
      const value = props.object[prop]
      return value !== undefined && value !== null && (!Array.isArray(value) || value.length > 0)
    }

    return { isPropValid }
  },
})
</script>

<style scoped>
hr {
  margin: 0.5rem 0;
}
</style>
