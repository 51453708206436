<template>
  <table class="table table-striped table-hover table-bordered">
    <thead>
      <tr>
        <th v-for="(header, i) in filteredHeaders" :key="i">
          <div class="d-flex justify-content-between" :class="{ sortable: header.sortable }" @click="sort(header)">
            {{ header.label }}
            <span
              v-if="header.sortable"
              :style="{ opacity: state.sorting === header.field ? 1 : 0 }"
              class="material-icons"
            >
              {{ state.direction === 'asc' ? 'arrow_drop_up' : 'arrow_drop_down' }}
            </span>
          </div>
        </th>
        <th v-if="$slots.actions"></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row, i) in renderRows" :key="i">
        <td v-for="(header, j) in filteredHeaders" :key="j">
          {{ format(header, row) }}
        </td>
        <td style="white-space: nowrap">
          <slot name="actions" :rowData="row" />
        </td>
      </tr>
    </tbody>
  </table>
  <nav v-if="pages > 0">
    <ul class="pagination justify-content-center">
      <li class="page-item me-2" v-if="state.page > 1">
        <a class="page-link" href="#" aria-label="Previous" @click="state.page = 0">
          <span aria-hidden="true">&laquo;&laquo;</span>
        </a>
      </li>
      <li class="page-item" v-if="state.page !== 0">
        <a class="page-link" href="#" aria-label="Previous" @click="prevPage">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <li class="page-item" v-if="state.page === pages && pages > 2">
        <a class="page-link" @click="state.page = state.page - 2">
          {{ state.page - 1 }}
        </a>
      </li>
      <li class="page-item" v-if="state.page > 0">
        <a class="page-link" @click="state.page = state.page - 1">
          {{ state.page }}
        </a>
      </li>
      <li class="page-item active">
        <a class="page-link">{{ state.page + 1 }}</a>
      </li>
      <li class="page-item" v-if="state.page < pages">
        <a class="page-link" @click="state.page = state.page + 1">
          {{ state.page + 2 }}
        </a>
      </li>
      <li class="page-item" v-if="state.page === 0 && pages > 2">
        <a class="page-link" @click="state.page = state.page + 2">
          {{ state.page + 3 }}
        </a>
      </li>
      <li class="page-item" v-if="state.page < pages">
        <a class="page-link" href="#" aria-label="Next" @click="nextPage">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
      <li class="page-item ms-2" v-if="state.page < pages - 1">
        <a class="page-link" href="#" aria-label="Next" @click="state.page = pages">
          <span aria-hidden="true">&raquo;&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" scoped>
import { defineComponent, PropType, reactive, computed, watchEffect } from 'vue'
import moment from 'moment'
import _ from 'lodash'

type Header = {
  field: string
  label: string
  type?: string
  sortable?: boolean
}

type TableState = {
  sorting?: string
  direction?: string
  page: number
}

function fieldType(field: String, value: string) {
  if (field.indexOf('user_') !== -1) {
    return typeof value === 'string' ? undefined : 'user'
  }
  if (field.indexOf('date_') !== -1) {
    return 'timestamp'
  }
  return typeof value
}

export default defineComponent({
  name: 'Table',
  props: {
    rows: {
      type: Array as PropType<Array<any>>,
      required: true,
    },
    headers: {
      type: Array as PropType<Array<Header>>,
      required: false,
      default: ({ rows }: any) => {
        return Object.keys(rows[0])
          .map((field) => ({
            field,
            sortable: true,
            label: _.startCase(field),
            type: fieldType(field.toLowerCase(), rows[0][field]),
          }))
          .filter((h) => h.type !== 'object')
      },
    },
    perPage: {
      type: Number,
      default: 10,
    },
  },
  setup(props) {
    const state = reactive<TableState>({
      sorting: undefined,
      direction: 'asc',
      page: 0,
    })

    const sort = (header: Header) => {
      if (header.sortable) {
        if (!state.sorting) {
          state.sorting = header.field
          state.direction = 'asc'
        } else {
          if (state.direction === 'asc') {
            state.direction = 'desc'
          } else if (state.direction === 'desc') {
            state.sorting = undefined
          }
        }
      }
    }

    const format = (header: Header, row: any) => {
      if (header.type === 'timestamp') {
        return moment(row[header.field]).format('DD/MM/YYYY hh:mm:ss')
      }
      if (header.type === 'user' && row[header.field]) {
        const { first_name, last_name, email } = row[header.field]
        return first_name && last_name ? `${first_name} ${last_name}` : email
      }
      if (Array.isArray(row[header.field])) {
        return row[header.field].join(', ')
      }
      return row[header.field]
    }

    const filteredHeaders = computed(() => {
      return props.headers.filter((h) => h.type)
    })

    const sortedRows = computed(() => {
      if (state.sorting) {
        const sorted = props.rows.slice().sort((a, b) => {
          if (state.sorting) {
            if (typeof a[state.sorting] === 'string') {
              return a[state.sorting].localeCompare(b[state.sorting])
            }
            if (typeof a[state.sorting] === 'number') {
              return a[state.sorting] - b[state.sorting]
            }
          }
          return 0
        })
        if (state.direction === 'desc') {
          sorted.reverse()
        }
        return sorted
      } else {
        return props.rows
      }
    })

    const renderRows = computed(() => {
      const rows = sortedRows.value
      const lastIndex = Math.min((state.page + 1) * props.perPage - 1, rows.length)
      return rows.slice(state.page * props.perPage, lastIndex)
    })

    const pages = computed(() => Math.floor(sortedRows.value.length / props.perPage))

    const nextPage = () => {
      state.page = Math.min(pages.value, state.page + 1)
    }

    const prevPage = () => {
      state.page = Math.max(0, state.page - 1)
    }

    watchEffect(() => {
      if (state.page > pages.value) {
        state.page = 0
      }
    })

    return {
      format,
      state,
      sort,
      filteredHeaders,
      renderRows,
      pages,
      prevPage,
      nextPage,
    }
  },
})
</script>

<style lang="scss" scoped>
th {
  user-select: none;
}
.sortable {
  cursor: pointer;
}
</style>
