import { reactive } from 'vue'
import axios from '@/api'

import { useSessionStorage } from '@vueuse/core'

type Project = {
  id: number
  name: string
  dashboard_embed: string
  lat: number
  lng: number
  languages: string[]
}

type ProjectState = {
  active?: Project
  projects: Project[]
}

export const state = useSessionStorage<ProjectState>('projects', {
  active: undefined,
  projects: []
})

export const isProjectSelected = () => {
  return !!(state.value.active ?? false)
}

export const getProjectId = () => {
  return isProjectSelected() ? state.value.active?.id || -1 : -1
}

export const setProjectId = (projectId: number) => {
  state.value.active = state.value.projects.find((project) => project.id === projectId)
}

export const fetchProjects = async () => {
  const response = await axios.get('/items/projects')
  if (response?.data?.data) {
    state.value.projects = response?.data?.data;
    if (state.value.active) {
      const project = state.value.projects.find((project) => project.id === state.value.active?.id);
      if (!project) state.value.active = undefined;
    }
  }
}

export default state
