import { isEqual } from 'lodash'
import { pushChange } from '@/store/changes'

export function updateValue(key: string, original: any, newData: any, data?: any) {
  if (original) {
    let allEqual = true
    for (let key of Object.keys(data)) {
      let prop = original[key]
      if (Array.isArray(data[key])) {
        prop = prop ?? []
      }
      if (!isEqual(prop, data[key])) {
        allEqual = false
      }
    }

    if (!allEqual) {
      Object.assign(newData, data)
      pushChange(key, original.id, data)
    } else {
      pushChange(
        key,
        original.id,
        Object.keys(data).reduce((p, c) => ({ [c]: undefined }), {}),
      )
    }
  }
}

export function updateField(
  key: string,
  original: { fields: FieldValue[] | undefined },
  field: { id?: number; value: any; locked?: boolean },
) {
  if (original) {
    const existing = original?.fields.find((f) => f.id === field.id)
    if (!existing) return
    delete field.id

    let allEqual = true
    for (let key of Object.keys(field)) {
      if (!isEqual((existing as any)[key], (field as any)[key])) {
        allEqual = false
      }
    }

    if (!allEqual) {
      pushChange(key, existing.id, field)
    } else {
      pushChange(
        key,
        existing.id,
        Object.keys(field).reduce((p, c) => ({ [c]: undefined }), {}),
      )
    }
  }
}

export function groupFields(original: { fields: FieldValue[] | undefined } | undefined, fields: Field[] | undefined) {
  const groups: { name: String; fields: Field[] }[] = []
  for (let field of fields ?? []) {
    let group = groups.find((g) => g.name === field.group)
    if (!group) {
      group = { name: field.group, fields: [] }
      groups.push(group)
    }

    if (!original?.fields?.find((f) => f.fields_id.id === field.id)) {
      group.fields.push(field)
    }
  }
  return groups.filter((g) => g.fields.length > 0)
}
