import { createI18n } from 'vue-i18n'

import enUS from '../assets/flags/en-US.svg'
import frFR from '../assets/flags/fr-FR.svg'
import nlNL from '../assets/flags/nl-NL.svg'
import itIT from '../assets/flags/it-IT.svg'
import esES from '../assets/flags/es-ES.svg'

const i18n = createI18n({
  locale: 'fr-FR',
  fallbackLocale: 'en-US',
  messages: {
    'en-US': {
      logo: enUS,
    },
    'fr-FR': {
      logo: frFR,
    },
    'nl-NL': {
      logo: nlNL,
    },
    'it-IT': {
      logo: itIT,
    },
    'es-ES': {
      logo: esES,
    },
  },
})

export default i18n
