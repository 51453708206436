<template>
  <div class="btn-group w-100">
    <button
      :disabled="disabled"
      class="btn btn-light d-flex justify-content-between dropdown-toggle text-capitalize w-100 overflow-hidden gap-2"
      type="button"
      id="dropdownMenuClickableInside"
      data-bs-toggle="dropdown"
      data-bs-auto-close="outside"
      aria-expanded="false"
    >
      <div class="d-flex flex-grow-1 gap-2 flex-wrap align-items-center overflow-hidden">
        <template v-if="!selected || selected.length === 0 || !options">
          {{ placeholder }}
        </template>
        <template v-else v-for="(index, i) of selected" :key="i">
          <span class="badge bg-primary focus:border-primary">
            <slot :option="options?.find((o) => (o.id ?? o) === index)">{{ index }}</slot>
          </span>
        </template>
      </div>
    </button>
    <ul
      class="dropdown-menu overflow-auto"
      aria-labelledby="dropdownMenuClickableInside"
      style="min-width: 100%; max-width: 100%; max-height: 300px"
      v-if="options"
    >
      <div class="search-wrapper">
        <div class="search option border w-100 m-0 px-2">
          <input type="text" v-model="searchQuery" />
          <span class="material-icons ms-2">search</span>
        </div>
      </div>
      <li
        v-for="option in filteredOptions"
        :key="option"
        class="option d-flex justify-content-between px-2 border align-items-center"
        :class="{ 'border-primary': selected && selected.includes(option.id ?? option) }"
      >
        <input
          class="input form-check-input"
          type="checkbox"
          value=""
          :id="option.id ?? option"
          @input="$emit('selected', option)"
          :checked="selected && selected.includes(option.id ?? option)"
          style="height: 1.5rem; width: 1.5rem"
        />
        <label
          class="label form-check-label flex-grow-1 text-capitalize text-nowrap text-end"
          :for="option.id ?? option"
        >
          <slot :option="option">{{ option }}</slot>
        </label>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { getLocalisedValue, isValueLocalised } from '@/utils/localisation';
import { computed, defineComponent, PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'CustomSelect',
  emits: ['selected'],
  props: {
    placeholder: {
      type: String,
    },
    options: {
      type: Array as PropType<Array<any>>,
    },
    selected: {
      type: Array as PropType<Array<Number>>,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    searchKeys: {
      type: Array as PropType<Array<String>>,
      default: ['title']
    }
  },
  setup(props, { emit }) {
    const searchQuery = ref('');
    const i18n = useI18n();

    const filteredOptions = computed(() => {

      //const { options, searchKeys } = props;

      const searchQueryNorm = searchQuery.value.normalize('NFD').replace(/\p{Diacritic}/gu, "").trim().toLowerCase();
      if (!searchQueryNorm) return props.options;

      return props.options?.filter((option) => {
        for(const key of props.searchKeys) {
          const property = option[key as any];
          if (isValueLocalised(property)) {
            const localisedText = getLocalisedValue(property, i18n.locale.value).value?.normalize('NFD').replace(/\p{Diacritic}/gu, "").trim().toLowerCase();
            console.log(localisedText, searchQueryNorm)
            return localisedText?.includes(searchQueryNorm)
          }
          if (typeof property === 'string') {
            return property?.normalize('NFD').replace(/\p{Diacritic}/gu, "").trim().toLowerCase()?.includes(searchQueryNorm)
          }
        }
        return false;
      })
    })

    return { searchQuery, filteredOptions }
  },
})
</script>

<style lang="scss" scoped>
.badge {
  position: relative;
  top: unset !important;
}

.btn-group {
  min-width: 200px;
  min-height: fit-content;
  flex: 1;

  button {
    border: 1px solid #ced4da;
    color: #212529;
    &:disabled {
      background: #e9ecef;
    }
  }

  .search-wrapper {
    position: sticky;
    top: 0;
    padding: .5rem;
    width: 100%;
    background: #efefef;

    .search {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: none;
      background-color: white;

      input {
        flex: 1;
        background-color: transparent;
        border: none;
        outline: none;

        &:focus, &:active {

        }
      }
    }
  }

  .badge {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .dropdown-menu {
    padding: 0;
  }

  .option {
    margin: 0.5rem;
    height: 2.5rem;
    gap: 12px;
    .input {
      min-width: 24px;
      min-height: 24px;
    }
    .label {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }
  }
}
</style>
