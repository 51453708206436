<template>
  <div v-if="poi" class="d-flex flex-column flex-grow-1 position-relative">
    <PageHeader>
      <template #default>
        <div class="d-flex gap-2">
          <router-link class="btn btn-sm d-inline-flex m-0 p-0" :to="{ name: 'view-points_of_interest' }">
            <span class="material-icons me-2">undo</span>
          </router-link>
          Point of Interest
        </div>
      </template>
      <template #right>
        <text-input
          class="border"
          inputClass="fs-4"
          style="flex: 1 0"
          :value="newData.title"
          @change="updateValue('Points_of_Interest', poi, newData, { title: $event })"
        />
      </template>
    </PageHeader>
    <transition appear name="fade">
      <div
        class="content d-flex flex-column flex-xl-row-reverse flex-grow-1 position-relative gap-3 align-items-xl-start"
      >
        <div style="min-width: 290px; top: 1rem" class="sticky-xl-top">
          <div class="d-flex flex-column p-3 border rounded-3 bg-white mb-2">
            <div class="d-flex justify-content-between align-items-center gap-5">
              <span class="fs-7 fw-bolder text-muted">Status</span>
              <select
                class="form-select"
                :value="newData.status"
                @input="updateValue('Points_of_Interest', poi, newData, { status: $event.target.value })"
              >
                <option value="published">Published</option>
                <option value="draft">Draft</option>
                <option value="archived">Archived</option>
              </select>
            </div>
            <hr style="margin: 0.5rem 0" />

            <properties
              :object="{
                Name: poi.title,
                'POI Status': poi.poi_status,
                Source: poi.source,
                'Date Created': new Date(poi.date_created).toLocaleString(),
                'Last Updated': new Date(poi.date_updated).toLocaleString(),
              }"
            />
            <hr />
            <div class="d-flex align-items-center" style="width: 100%; overflow: hidden">
              <CSVEditor
                class="flex-grow-1"
                type="Tag"
                :values="newData.tags ?? []"
                @input="updateValue('Points_of_Interest', poi, newData, { tags: $event })"
              />
            </div>
            <hr />
            <div class="d-flex flex-column justify-content-between" style="height: 220px">
              <span class="fs-7 fw-bolder text-muted">Categories</span>
              <CategoryList
                v-if="categories"
                :categories="categories"
                :active="poi.categories ?? []"
                lock
                select
                small
                @categories="updateCategories"
                @lock="toggleLock"
              />
            </div>
          </div>
          <div class="d-flex justify-content-between gap-2">
            <div class="dropdown">
              <button
                class="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Add Field
              </button>
              <ul class="dropdown-menu overflow-auto" style="max-height: 300px">
                <template v-for="group in grouped_fields" :key="group.name">
                  <li>
                    <h5 class="m-0 px-2">{{ group.name }}</h5>
                    <div v-for="field in group.fields" :key="field.id">
                      <button class="dropdown-item" @click="addField(field.id)">
                        {{ field.name }}
                      </button>
                    </div>
                  </li>
                </template>
              </ul>
            </div>
            <div class="d-flex gap-2">
              <router-link
                class="btn btn-success btn-sm d-flex"
                :to="{ name: 'view-points_of_interest', params: { save: true } }"
              >
                <span class="material-icons me-2">save</span>
                <span class="fs-6">Save</span>
              </router-link>
            </div>
          </div>
        </div>

        <fields-edit :fields="poi.fields" @update="updateEditedField" />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, reactive } from 'vue'
import { useRoute } from 'vue-router'
import axios from '@/api'
import { isEqual } from 'lodash'

import { PageHeader, CSVEditor } from '@avriopolis/common/components'
import Auth from '@/store/auth'
import LocalisedValue from '@/components/misc/LocalisedValue.vue'
import FieldsEdit from '@/components/fields/FieldsEdit.vue'
import Properties from '@/components/misc/Properties.vue'
import WYSIWYG from '@/components/inputs/WYSIWYG.vue'
import TextInput from '@/components/inputs/TextInput.vue'
import CategoryList from '@/components/misc/CategoryList.vue'
import { pushChange } from '@/store/changes'
import project from '@/store/project'
import { updateValue, groupFields, updateField } from '@/utils/values'

export default defineComponent({
  name: 'Edit_Points_of_Interest',
  emits: ['loading'],
  components: {
    PageHeader,
    LocalisedValue,
    FieldsEdit,
    Properties,
    WYSIWYG,
    TextInput,
    CSVEditor,
    CategoryList,
  },
  setup(_props, { emit }) {
    const route = useRoute()
    const poi = ref<PointOfInterest>()
    const categories = ref<Category[]>()
    const fields = ref<Field[]>()
    const newData = reactive({
      title: poi.value?.title,
      status: poi.value?.status,
      tags: poi.value?.tags,
    })

    const fetchData = async () => {
      const poiData = await axios.get(
        `/items/points_of_interest/${route.params.id}?fields=*,fields.*,fields.fields_id.*,categories.categories_id.*,categories.id,categories.locked,fields.points_of_interest_id.projects_id.lng,fields.points_of_interest_id.projects_id.lat,fields.points_of_interest_id.projects_id.default_zoom
`,
      )
      poi.value = poiData?.data?.data

      Object.assign(newData, {
        title: poi.value?.title,
        status: poi.value?.status,
        tags: poi.value?.tags,
      })

      const categoriesData = await axios.get(`/items/categories/?filter[projects_id]=${project.value.active?.id}&fields=*.*`)
      categories.value = categoriesData?.data?.data

      const response_2 = await axios.get('/items/fields')
      fields.value = response_2?.data?.data
    }

    const grouped_fields = computed(() => {
      if (fields.value) {
        return groupFields(poi.value, fields.value)
      }
      return []
    })

    async function updateData() {
      emit('loading', true)
      try {
        await fetchData()
      } catch (e) {}
      emit('loading', false)
    }

    async function updateCategories(categories: number[]) {
      if (poi.value) {
        const existing = poi.value?.categories?.map((c) => ({ category: c.categories_id.id, id: c.id })) ?? []

        if (
          !isEqual(
            existing.map((e) => e.category),
            categories,
          )
        ) {
          pushChange('Points_of_Interest_Categories', poi.value?.id, {
            existing: existing,
            categories: categories,
          })
        } else {
          pushChange('Points_of_Interest_Categories', poi.value?.id, { existing: undefined, categories: undefined })
        }
      }
    }

    async function toggleLock({ id, locked }: { id: number; locked: boolean }) {
      if (poi.value) {
        const existing: any = { ...poi.value?.categories?.find((c) => c.categories_id.id === id) } //.map((c) => ({ category: c.categories_id.id, id: c.id, locked: c.locked ?? false })) ?? [];
        existing.categories_id = existing.categories_id?.id ?? existing.categories_id
        if (existing.locked != locked) {
          pushChange('Points_of_Interest_Categories_Locked', existing.id, { locked })
        } else {
          pushChange('Points_of_Interest_Categories_Locked', existing.id, { locked: undefined })
        }
      }
    }

    async function addField(fields_id: number) {
      await axios.post('items/points_of_interest_fields', {
        points_of_interest_id: poi.value?.id,
        fields_id,
        locked: false,
      })
      updateData()
    }

    async function updateEditedField(field: { id: number; value: any }) {
      if (poi.value) {
        return await updateField('Points_of_Interest_Field', poi.value, field)
      }
    }

    updateData()

    return {
      poi,
      categories,
      grouped_fields,
      addField,
      updateEditedField,
      pushChange,
      updateCategories,
      toggleLock,
      updateValue,
      newData,
      user: Auth.user,
    }
  },
  beforeRouteEnter(to, _from, next) {
    if (!to.params.tab) {
      return next({ ...to, params: { ...to.params, tab: 'info' } })
    }
    next()
  },
})
</script>
