<template>
  <transition
    name="custom"
    :appear="appear"
    :enter-active-class="`animate__animated animate__${enter}`"
    :leave-active-class="`animate__animated animate__${leave}`"
  >
    <slot />
  </transition>
</template>

<script lang="ts" scoped>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Animation',
  props: {
    appear: {
      type: Boolean,
      default: false,
    },
    enter: {
      type: String,
      required: true,
    },
    leave: {
      type: String,
      required: true,
    },
  },
})
</script>

<style scoped>
.animate__animated {
  transition-duration: 0.75s;
  animation-duration: 0.75s;
}
</style>
