<template>
  <div id="page-container" :class="{ navOpen }">
    <Sidebar @close-nav="navOpen = false" />
    <Header @open-nav="navOpen = true" />
    <div id="main-container" class="position-relative">
      <progress-bar v-if="loading" />
      <div class="h-100" data-simplebar>
        <router-view v-slot="{ Component }">
          <transition appear name="fade" mode="out-in">
            <component :key="$route.name" :is="Component" @loading="setLoading" />
          </transition>
        </router-view>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

import ProgressBar from '@/components/misc/ProgressBar.vue'
import Sidebar from '@/views/layout/Sidebar.vue'
import Header from '@/views/layout/Header.vue'
import 'simplebar'

export default defineComponent({
  name: 'LayoutIndex',
  components: {
    Sidebar,
    Header,
    ProgressBar,
  },
  setup() {
    const loading = ref(false)
    function setLoading(_loading: boolean) {
      loading.value = _loading
    }
    return { loading, setLoading, navOpen: ref(false) }
  },
})
</script>

<style lang="scss" scoped>
.progress {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: transparent;
  height: 10px;
  z-index: 1;

  .progress-bar-animated {
    animation: 0.25s linear infinite progress-bar-stripes;
  }
}

#page-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  min-width: 320px;
  height: 100vh;
  min-height: 100vh;
  background: #edf0f2;
  overflow: hidden;

  #main-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    max-width: 100%;
    margin-top: 4rem;
    overflow: hidden;
  }

  > #main-container .content,
  > #page-footer .content {
    max-width: 1920px;
  }
}

@media (min-width: 992px) {
  #page-container {
    padding-left: 240px;
  }
}

@media (min-width: 1200px) {
  #page-container {
    > #main-container .content,
    > #page-footer .content {
      width: 92%;
    }
  }
}
</style>
