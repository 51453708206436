<template>
  <div class="d-flex flex-column flex-grow-1 position-relative">
    <PageHeader>
      <template #default>Points of Interest</template>
      <template #right>
        <ul class="nav nav-pills">
          <li class="nav-item">
            <a class="nav-link disabled">Display as</a>
          </li>
          <li class="nav-item">
            <router-link class="nav-link d-flex" active-class="active" :to="{ params: { display_as: 'list' } }">
              <span class="material-icons">list</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link d-flex" active-class="active" :to="{ params: { display_as: 'map' } }">
              <span class="material-icons">place</span>
            </router-link>
          </li>
        </ul>
        <span class="hr-vert d-none d-md-flex" style="height: 2rem; background: rgba(0, 0, 0, 0.25)" />
        <button class="btn btn-primary d-flex btn-rounded text-nowrap" @click="createPointOfInterest">
          <span class="material-icons me-2">add</span>
          Create Point of Interest
        </button>
      </template>
    </PageHeader>
    <transition appear name="fade">
      <div v-if="state.meta" class="content d-flex flex-column flex-grow-1 position-relative">
        <div style="z-index: 2" class="card card-rounded mb-3">
          <div class="card-body">
            <Filter filtering="pois" :meta="state.meta" @filter="state.filters = $event" :datasets="state.datasets" />
          </div>
        </div>
        <transition appear name="fade" mode="out-in">
          <CMSTable
            ref="table"
            title="Points of Interest"
            v-if="$route.params.display_as === 'list'"
            :filters="state.filters"
            name="points_of_interest"
            view
            edit
          />
          <places-map v-else collection="points_of_interest" route="points_of_interest" :filters="state.filters" />
        </transition>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { NavigationGuard } from 'vue-router'
import axios from '@/api'
import Swal from 'sweetalert2'

import { PageHeader } from '@avriopolis/common/components'
import project from '@/store/project'
import { slugify } from '@/utils/misc'

import CMSTable from '@/components/misc/CMSTable.vue'
import PlacesMap from '@/components/misc/PlacesMap.vue'
import Filter from '@/components/misc/Filter.vue'
import { fetchCategories } from '@/store/cms'

const routeGuard: NavigationGuard = (to, _from, next) => {
  if (!to.params.display_as) {
    return next({ ...to, params: { ...to.params, display_as: 'list' } })
  }
  next()
}

export default defineComponent({
  name: 'Points_of_Interest',
  emits: ['loading'],
  components: {
    PageHeader,
    Filter,
    CMSTable,
    PlacesMap,
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const table = ref()
    const state = reactive({
      meta: undefined,
      filters: {},
      datasets: {
        categories: [],
      },
    })

    const fetchData = async () => {
      const poi_meta = await axios.get(`/meta/poi?filter[projects_id]=${project.value?.active?.id}`)
      state.meta = poi_meta?.data ?? poi_meta
      state.datasets.categories = await fetchCategories()
    }

    async function updateData() {
      emit('loading', true)
      try {
        await fetchData()
      } catch (e) {}
      emit('loading', false)
    }

    async function createPointOfInterest() {
      const result = await Swal.fire({
        input: 'text',
        inputLabel: 'Point of interest Title',
        inputPlaceholder: 'Title',
        showCancelButton: true,
        confirmButtonText: 'Create',
        confirmButtonColor: '#0d6efd',
      })

      if (result.isConfirmed) {
        if (!result.value) {
          Swal.fire('Invalid', 'A title must be entered', 'error')
        } else {
          await axios.post('items/points_of_interest', {
            title: [{ lang: i18n.locale.value, value: result.value }],
            source: 'manual',
            slug: slugify(result.value),
            projects_id: project.value.active?.id,
          })

          table?.value?.table?.update()
        }
      }
    }

    updateData()

    return { state, table, createPointOfInterest }
  },
  beforeRouteUpdate: routeGuard,
  beforeRouteEnter: routeGuard,
})
</script>
