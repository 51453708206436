<template>
  <div class="d-flex flex-column flex-grow-1 position-relative">
    <PageHeader>
      <template #default>Media Management</template>
    </PageHeader>
    <div class="content">
      <media-viewer @loading="$emit('loading', $event)" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { PageHeader } from '@avriopolis/common/components'
import MediaViewer from '@/components/media/MediaViewer.vue'

export default defineComponent({
  name: 'Dashboard',
  emits: ['loading'],
  components: {
    PageHeader,
    MediaViewer,
  },
})
</script>
