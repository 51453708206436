export const defualtToolbarItems = [
  'heading',
  'bold',
  'italic',
  'strike',
  'divider',
  'hr',
  'divider',
  'ul',
  'ol',
  'divider',
  'table',
  'image',
  'link',
  'divider',
  'code',
]
