import { i18n } from '@avriopolis/common'

import en from './locales/en'
import fr from './locales/fr'
import it from './locales/it'
import es from './locales/es'

i18n.global.setLocaleMessage('en-US', {
  ...i18n.global.getLocaleMessage('en-US'),
  ...en,
})

i18n.global.setLocaleMessage('fr-FR', {
  ...i18n.global.getLocaleMessage('fr-FR'),
  ...fr,
})

i18n.global.setLocaleMessage('it-IT', {
  ...i18n.global.getLocaleMessage('it-IT'),
  ...it,
})

i18n.global.setLocaleMessage('es-ES', {
  ...i18n.global.getLocaleMessage('es-ES'),
  ...es,
})

export default i18n
