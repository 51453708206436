<template>
  <form class="px-2">
    <div class="icon-fields form-group d-flex mb-4">
      <div class="icon_container border rounded">
        <img v-if="previousIcon" class="icon" alt="" :src="`${mediaBase}/assets/${previousIcon}`" />
      </div>
      <div>
        <label for="icon" class="form-label">{{ $t('customIcon.choseIcon') }}</label>
        <input
          ref="icon"
          class="form-control"
          type="file"
          id="icon"
          name="icon"
          accept="image/png"
          v-on:change="updateIcon"
        />
      </div>
    </div>
    <div class="size-fields form-group d-flex align-items-center mb-4">
      <label for="size" class="size_label font-weight-bold form-label">{{ $t('customIcon.size') }}:</label>
      <Slider
        :lazy="false"
        v-on:update="updateSize"
        v-on:change="emitSize"
        :min="0"
        :max="1"
        :step="0.05"
        v-model="size"
        :format="formatValue"
        class="slider"
      />
    </div>
    <div class="form-group mb-4 p-1 map_wrap">
      <inputPosition
        @update="updatePosition"
        :size="parseFloat(size)"
        :position="position.coordinates"
        :image="previousIcon ? previousIcon : undefined"
        :zoom="zoom"
      />
    </div>
  </form>
</template>
<script lang="ts">
import axios from '@/api'
import { defineComponent, defineEmit, defineProps, reactive, ref, computed, getCurrentInstance, watchEffect } from 'vue'
import inputPosition from '@avriopolis/common/components/inputPosition.vue'
import Slider from '@vueform/slider'
export default defineComponent({
  name: 'customIcon',
  emits: ['update'],
  components: {
    inputPosition,
    Slider,
  },
  props: {
    previousIcon: {
      type: String,
    },
    size: {
      type: Number,
    },
    position: {
      type: null,
    },
    zoom: {
      type: Number,
    },
  },
  setup(props, { emit }) {
    const loading = ref<boolean>(false)
    const files = ref<any>(undefined)
    const icon = ref<any>()
    const size = ref<string>(props.size ? props.size.toString() : '0.5')
    const position: any = ref<any>(props.position ? props.position : undefined)
    const canSave = computed(() => {
      if (props?.previousIcon) return ''
      return files.value ? '' : 'disabled'
    })
    const mediaBase = import.meta.env.VITE_API_URL

    async function updateCustomIcon() {
      let finalIcon = ''
      if (files.value && files.value.length > 0) {
        finalIcon = icon.value
      } else if (props.previousIcon) finalIcon = props.previousIcon

      if (finalIcon && size.value && position.value) {
        let output = {
          icon: finalIcon,
          size: parseFloat(size.value),
          position: position.value,
        }
        emit('update', output)
      }
      files.value = undefined
    }

    async function updateIcon(e: any) {
      files.value = e.target.files
      icon.value = await uploadImage(e.target.files)
      updateCustomIcon()
    }

    function updateSize(e: any) {
      size.value = e
    }

    function emitSize(e: any) {
      size.value = e
      updateCustomIcon()
    }

    function updatePosition(e: any) {
      let pos = {
        type: 'point',
        coordinates: [e.lng, e.lat],
      }
      position.value = pos
      updateCustomIcon()
    }

    async function uploadImage(image: any) {
      const formData = new FormData()
      formData.append('title', 'custom_icon')
      formData.append('file', image[0])
      try {
        let uploadedImage = await axios.post('/files', formData)
        return uploadedImage.data.data.id
      } catch (e) {
        console.error('outch', e)
      }
      return 0
    }

    function formatValue(val: string) {
      return (parseFloat(val) * 100).toFixed(0) + '%'
    }

    return {
      updateIcon,
      updateSize,
      emitSize,
      updatePosition,
      updateCustomIcon,
      size,
      icon,
      files,
      position,
      canSave,
      loading,
      mediaBase: import.meta.env.VITE_API_URL,
      inputPosition,
      Slider,
      formatValue,
    }
  },
})
</script>
<style scoped>
.icon_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
}

.size_label {
  width: 100px;
}

.icon {
  width: 70%;
}

.icon-fields,
.size-fields {
  gap: 1rem 2rem;
  flex-wrap: wrap;
}
.map_wrap >>> .mapboxgl-ctrl-bottom-left,
.map_wrap >>> .mapboxgl-ctrl-bottom-right {
  display: none;
}

.map_wrap {
  height: 400px;
  position: relative;
}

.map {
  height: 400px;
}

.size_label {
  font-weight: 600;
  line-height: 1.25;
  color: #373737;
  font-size: 1rem;
}

.slider {
  padding: 0px;
  width: 100%;
  max-width: 350px;
  --slider-connect-bg: #017bfe;
  --slider-tooltip-bg: #017bfe;
  --slider-handle-ring-color: #017bfe80;
}

@media screen and (max-width: 768px) {
  .size-fields {
    margin-top: 2.5rem;
  }
  .slider {
    max-width: 200px;
  }
}
</style>
