<template>
  <div class="main-container shadow-sm bg-white" :class="bg_card">
    <div class="container-top">
      <i v-if="pingState === 'offline'" class="material-icons" :class="icon_class">error_outline</i>
      <div class="title">{{ flattenedNameOrDefault }}({{ name }})</div>
    </div>
    <div class="container" :class="{ containerLandscape: !ssOrientationPortrait }">
      <div class="tl-container" :class="bl_class">
        <i class="material-icons ping-icon" :class="icon_class">network_ping</i>
        <div class="l-title">{{ $t('dashboard.status.ping_title') }}</div>
        <div v-if="!refreshing" class="ping-value" :class="l_value_class">
          {{ date_ping }}
        </div>
        <div v-else>--</div>
      </div>
      <div class="bl-container" :class="bl_class">
        <i class="material-icons ss-icon" :class="icon_class">screenshot_monitor</i>
        <div class="l-title">{{ $t('dashboard.status.screenshot_title') }}</div>
        <div v-if="!refreshing" class="ss-value" :class="l_value_class">
          {{ date_screenShot }}
        </div>
        <div v-else>--</div>
      </div>
      <div class="center-container">
        <div class="container-image" :class="{ containerImageLandscape: !ssOrientationPortrait }">
          <img
            ref="image"
            v-if="screenShot && !refreshing"
            alt="screenshot image"
            class="ss_image"
            :src="screenShotURL"
            @load="detectOrientation"
          />
          <div v-if="refreshing" class="image-refreshing">
            <i class="material-icons ss-icon" :class="{ 'icon-cached': refreshing }">cached</i>
          </div>
        </div>
      </div>
      <div class="r1-container">
        <div class="right-box">
          <div class="cont-R-title">
            <div class="rightbox_title">{{ $t('dashboard.status.software_title') }}</div>
            <div class="r-value">
              {{ playerVersion ? 'v. ' + playerVersion : '' }}
            </div>
          </div>
          <div class="r-value">{{ player_upTime }}</div>
        </div>
      </div>

      <div class="r3-container">
        <div class="right-box">
          <div class="cont-R-title">
            <div class="rightbox_title">{{ $t('dashboard.status.hardware_title') }}</div>
            <div class="r-value">
              {{ projectVersion ? 'v. ' + projectVersion : '' }}
            </div>
          </div>
          <div class="r-value">{{ project_upTime }}</div>
        </div>
      </div>
    </div>

    <div class="network-container">
      <span class="rightbox_title">Data Consumption</span>
      <div class="network-data-container">
        <div class="r5-container" v-for="(network, key) in networkUsage">
          <div class="network" v-if="key != 'default'">
            <span class="">{{ key }}</span>
            <span>{{ getConsumption(Math.floor(network)) }}</span>
          </div>
          <div v-else>-</div>
        </div>
      </div>
    </div>

    <div class="button-container">
      <button
        v-if="!refreshing"
        type="button"
        class="button btn btn-outline-primary w-100 btn-sm"
        @click="refreshNow(id)"
        :class="['btn-outline-primary']"
      >
        {{ buttonLabel }}
      </button>
      <button
        v-if="refreshing"
        type="button"
        class="button btn btn-outline-primary w-100 btn-sm"
        :class="['btn-outline-warning', 'button-refreshing']"
      >
        {{ buttonLabel }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import moment from 'moment'
import axios from '@/api'
import { useI18n } from 'vue-i18n'
import { monaco_kiosk_names } from '@/monaco_kiosk_names'
import { computed, defineComponent, PropType, ref } from 'vue'
import { consumption } from '@/store/kiosk'

export default defineComponent({
  name: 'ScreenCard',
  emits: ['updateData'],
  props: {
    name: {
      type: String,
      required: false,
      default: 'Kiosk Name',
    },
    flattenedName: {
      type: String,
      required: false,
    },
    id: {
      type: Number,
      required: false,
      default: 'Kiosk Id',
    },
    lastPing: {
      type: String,
      required: false,
      default: 0,
    },
    screenShot: {
      type: Object,
      required: false,
    },
    restart: {
      type: Boolean,
      required: false,
    },
    playerVersion: {
      type: String,
      default: 'player version',
    },
    playerUptime: {
      type: String,
      default: 'player up',
    },
    projectVersion: {
      type: String,
      default: 'project version',
    },
    machineUptime: {
      type: String,
      default: 'machine up',
    },
    networkUsage: {
      type: Object,
      default: {
        default: 0,
      },
    },
  },
  components: {},
  setup(props, { emit }) {
    const i18n = useI18n()
    const { t } = useI18n()
    const image = ref()
    const ssOrientationPortrait = ref(true)

    // set offlineDelay in ms
    const warningLineDelay = 300000
    const offLineDelay = 3600000

    const pingDelay = computed(() => -moment(props.lastPing).diff(moment()))

    const pingState = computed(() => {
      let state
      if (pingDelay.value >= offLineDelay) {
        state = 'offline'
      } else if (pingDelay.value >= warningLineDelay) {
        state = 'warning'
      } else {
        state = 'active'
      }
      return state
    })
    const refreshing = computed(() => props?.restart)
    const date_ping = computed(() => (moment(props?.lastPing).isValid() ? moment(props?.lastPing).fromNow() : '--'))
    const date_screenShot = computed(() =>
      moment(props?.screenShot?.modified_on ?? props?.screenShot?.uploaded_on).fromNow(),
    )
    const player_upTime = computed(() =>
      props?.playerUptime ? moment(moment().valueOf() - parseInt(props?.playerUptime) * 1000).fromNow(true) : '-',
    )
    const project_upTime = computed(() =>
      props?.machineUptime ? moment(moment().valueOf() - parseInt(props?.machineUptime) * 1000).fromNow(true) : '-',
    )

    const buttonLabel = computed(() =>
      props?.restart ? t('dashboard.status.button_labels.refreshing') : t('dashboard.status.button_labels.restart'),
    )
    const l_value_class = computed(() => 'l-value-' + pingState?.value)
    const bl_class = computed(() => (refreshing.value ? 'bl-tl-refreshing' : 'bl-tl-' + pingState.value))
    const icon_class = computed(() => (refreshing.value ? 'icon-refreshing' : 'icon-' + pingState.value))
    const bg_card = computed(() => (refreshing.value ? 'bg-refreshing' : 'bg-' + pingState.value))
    const screenShotURL = computed(
      () =>
        `${import.meta.env.VITE_API_URL}/assets/${props?.screenShot?.id}?lm=${
          props?.screenShot?.modified_on ?? props?.screenShot?.uploaded_on
        }`,
    )
    const flattenedNameOrDefault = computed(() => {
      if (props?.flattenedName) return props?.flattenedName
      if (props?.name && (props.name.startsWith('CCF_MC_') || props.name.startsWith('BRN'))) {
        const found = monaco_kiosk_names.find(
          (kiosk: { GLOOH_ID: string; NAME: string }) => kiosk.GLOOH_ID === props.name,
        )
        if (found) return found.NAME.replaceAll('_', ' ')
      }
      return props?.name
    })

    function detectOrientation() {
      ssOrientationPortrait.value = image.value.naturalWidth > image.value.naturalHeight ? false : true
    }

    async function refreshNow(id: number | string) {
      await axios.patch(`/items/statuses/${id}`, { restart: true })
      emit('updateData')
    }

    function getConsumption(val: number) {
      if (val == 0) return '-'
      if (val < 1000) return val + ' MB'
      return (val / 1000).toFixed(2) + ' GB'
    }

    return {
      date_ping,
      date_screenShot,
      screenShotURL,
      refreshing,
      buttonLabel,
      detectOrientation,
      image,
      ssOrientationPortrait,
      bl_class,
      l_value_class,
      icon_class,
      bg_card,
      pingState,
      player_upTime,
      project_upTime,
      refreshNow,
      t,
      getConsumption,
      flattenedNameOrDefault,
    }
  },
})
</script>

<style lang="scss" scoped>
.main-container {
  padding: 25px;
  overflow: hidden;

  @media only screen and (max-width: 600px) {
    max-width: 90vw;
    min-height: 0;
  }
}
.container {
  overflow: hidden;
  padding: 0;
  margin: 0;
  margin-top: 20px;
  display: grid;

  grid-auto-flow: row;

  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100px 100px 50px;
  gap: 10px 10px;
  grid-template-areas:
    'tl-container center-container '
    'bl-container center-container '
    'r1-container r3-container';
}

.containerLandscape {
  grid-template-areas:
    'tl-container bl-container '
    'center-container center-container '
    'r1-container r3-container';
}

.container-top {
  display: inline-flex;
  gap: 10px;
  overflow: hidden;
}

.bg-offline {
  //border: 1px solid rgba(234, 84, 85);
}

.tl-container,
.bl-container {
  border-radius: 6px;
  padding: 10px;
  overflow: hidden;
  white-space: nowrap;
}
.tl-container {
  grid-area: tl-container;
  background: rgba(13, 110, 253, 0.08);
}

.bl-container {
  grid-area: bl-container;
  background: rgba(3, 147, 83, 0.13);
}

.bl-tl-warning {
  background: rgba(255, 193, 7, 0.08);
}

.bl-tl-offline {
  background: rgba(234, 84, 85, 0.14);
}

.bl-tl-refreshing {
  background: #f8f8f8;
}

.l-title {
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.72);
  margin-top: 10px;
}

.ping-icon {
  color: #0d6efd;
}

.ss-icon {
  color: #039353;
}

.icon-warning {
  color: rgba(255, 193, 7);
  opacity: 0.5;
}

.icon-offline {
  color: rgba(234, 84, 85);
  opacity: 0.5;
}

.icon-refreshing {
  color: lightgray;
  opacity: 0.5;
}

.icon-cached {
  color: #ffc007;
  opacity: 0.5;
  animation: rotation 3s infinite linear;
}

.ping-value {
  font-size: 12px;
  color: #0d6efd;
}
.ss-value {
  font-size: 12px;
  color: #039353;
}

.l-value-warning {
  color: rgba(255, 193, 7);
}

.l-value-offline {
  color: rgba(234, 84, 85);
}

.l-value-refreshing {
  color: #f8f8f8;
}

.center-container {
  grid-area: center-container;
}

.r-value {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.72);
}

.r1-container,
.r2-container,
.r3-container,
.r4-container,
.r5-container {
  background: #f8f8f8;
  border-radius: 6px;
  padding: 5px;

  overflow: hidden;
}

.r1-container {
  grid-area: r1-container;
}
.r2-container {
  grid-area: r2-container;
}
.r3-container {
  grid-area: r3-container;
}
.r4-container {
  grid-area: r4-container;
}
.r5-container {
  margin-top: 5px;
  padding: 0px 5px 0px 5px;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.72);
  flex-grow: 1;
}

.network-container {
  background: #f8f8f8;
  border-radius: 6px;
  padding: 10px 5px;

  margin-top: 10px;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.72);

  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.network-data-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.network {
  display: flex;
  flex-direction: column;
  gap: 5px 0px;
}

.ss_image {
  object-fit: cover;
  width: 100%;
  max-height: 100%;
  filter: invert(100%) grayscale(1);
  border-radius: 6px;
  transition: 0.4s;
}

.container-image {
  width: 117px;
  height: 208px;
  overflow: hidden;
  border-radius: 6px;
  background: #f8f8f8;
}

.containerImageLandscape {
  width: 250px;
  height: 100px;
  overflow: hidden;
}

.ss_image:hover {
  transform: scale(1.05);
  filter: none;
}

.image-refreshing {
  width: 100%;
  height: 100%;

  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.title {
  font-weight: 500;
  font-size: 16px;
}

.status {
  font-weight: 400;
  font-size: 12px;
  color: lightgray;
}

.button-container {
  margin-top: 20px;
  width: 100%;
}

.button {
  border-radius: 10px;
}

.button-refreshing {
  pointer-events: none;
  border: 0.5px dotted;
}

.rightbox_title {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.35);
}

.cont-R-title {
  display: inline-flex;
  gap: 5px;
}
</style>

<style>
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
