<template>
  <div class="container mx-2">
    <div class="" style="display: flex; justify-content: space-between; align-items: center">
      <h6 class="my-4 d-flex gap-2 align-items-end justify-content-between">Day Parting</h6>
      <button class="btn btn-outline-secondary btn-sm" @click="toogleAll()">Reset All</button>
    </div>
    <div class="grid-cols-hours-preset">
      <div class="grid_element_days_label"></div>
      <div
        v-for="preset in timePresetsLabels"
        class="grid_element_hours_preset"
        @click="selectAllFromHourPreset(preset)"
      >
        {{ preset }}
      </div>
    </div>

    <div class="grid-cols">
      <div class="grid_element_days_label"></div>
      <div
        v-for="(value, label, index) in labelHours"
        class="grid_element_hours_label"
        :class="value.focus ? labelFocusClass : null"
        @click="selectAllFromHour(label)"
        selectAllFromHour
      >
        {{ label }}
      </div>
    </div>

    <div
      class="grid-rows"
      @click="mouseClickGrid($event)"
      @mousedown="mouseDownGrid($event)"
      @mouseup="mouseUpGrid($event)"
      @mouseleave="mouseLeaveGrid($event)"
    >
      <div v-for="(value, day, index) in formattedData" class="grid_element_row">
        <div class="grid-cols">
          <div
            class="grid_element_days_label"
            :class="value.focus ? labelFocusClass : null"
            @click="selectAllFromDay(day.toString())"
          >
            {{ day }}
          </div>
          <div
            v-for="(availability, index) in value.availability"
            class="grid_element_col"
            :class="availability ? 'not_selected_cell' : 'selected_cell'"
            :data-day="day"
            :data-hour="index"
            data-type="cell"
            @mouseover="hoverCell($event, day, index)"
            @mouseleave="leaveHoverCell(day, index)"
            @click="switchCellState(day, index)"
          ></div>
        </div>
      </div>
    </div>

    <div class="my-4 container-legend">
      <div class="legend">
        <div class="box grid_element_col not_selected_cell">&nbsp;</div>
        Active
      </div>
      <div class="legend">
        <div class="box grid_element_col selected_cell">&nbsp;</div>
        Not active
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, onMounted, reactive, watch } from 'vue'

import { debounce, throttle } from 'lodash'

type FormattedData = {
  [key: string]: {
    focus: boolean
    availability: boolean[]
  }
}

type LabelHours = {
  [key: string]: {
    focus: boolean
  }
}

export default defineComponent({
  name: 'Availability',
  props: {
    dayparting: {
      type: (Object as PropType<Dayparting>) || null,
      required: false,
    },
  },

  emits: ['updateDayparting'],

  components: {},
  setup(props, { emit }) {
    const timePresetsLabels = ['Night', 'Morning', 'Afternoon', 'Evening']

    const formattedData = reactive<FormattedData>({})
    const labelHours = reactive<LabelHours>({})

    const labelFocusClass = ref('label_focus')
    const paintActive = ref(false)
    const paintModeAvaiable = ref()

    const timeout = ref()

    const dayparting_data: any = props?.dayparting ?? {
      mon: Array(24).fill(1),
      tue: Array(24).fill(1),
      wed: Array(24).fill(1),
      thu: Array(24).fill(1),
      fri: Array(24).fill(1),
      sat: Array(24).fill(1),
      sun: Array(24).fill(1),
    }

    function hoverCell(e: Event, day: any, hour: number) {
      labelHours[hour].focus = true
      formattedData[day].focus = true
      if (paintActive.value) {
        formattedData[day].availability[hour] = paintModeAvaiable.value
      }
    }

    function leaveHoverCell(day: any, hour: number) {
      labelHours[hour].focus = false
      formattedData[day].focus = false
    }

    function switchCellState(day: number | string, hour: number) {
      formattedData[day].availability[hour] = !formattedData[day].availability[hour]
    }

    function selectAllFromHour(hour: string | number) {
      const allSelected = Object.values(formattedData).every((day: any) => day.availability[hour])
      Object.values(formattedData).forEach((day: any) => (day.availability[hour] = !allSelected))
    }

    function selectAllFromDay(day: string) {
      const allSelected = formattedData[day].availability.every((hour: any) => hour)
      formattedData[day].availability = formattedData[day].availability.map((hour: any) => !allSelected)
    }

    function selectAllFromHourPreset(preset: string) {
      const allSelected = Object.values(formattedData).every((day: any) => {
        const hours = getHoursFromPreset(preset)
        return hours.every((hour: number) => day.availability[hour])
      })
      Object.values(formattedData).forEach((day: any) => {
        const hours = getHoursFromPreset(preset)
        hours.forEach((hour: number) => (day.availability[hour] = !allSelected))
      })
    }

    function getHoursFromPreset(preset: string): number[] {
      switch (preset) {
        case 'Night':
          return [0, 1, 2, 3, 4, 5]
        case 'Morning':
          return [6, 7, 8, 9, 10, 11]
        case 'Afternoon':
          return [12, 13, 14, 15, 16, 17]
        case 'Evening':
          return [18, 19, 20, 21, 22, 23]
        default:
          return []
      }
    }

    function mouseClickGrid(e: Event) {
      e.preventDefault()
      e.stopImmediatePropagation()
      if ((e?.target as any)?.dataset?.type === 'cell') {
        availabilityFromTarget((e?.target as any)?.dataset?.day, (e?.target as any)?.dataset?.hour)
      }
    }

    function mouseDownGrid(e: Event) {
      e.preventDefault()
      e.stopImmediatePropagation()
      if ((e?.target as any)?.dataset?.type === 'cell') {
        availabilityFromTarget((e?.target as any)?.dataset?.day, (e?.target as any)?.dataset?.hour)
        paintActive.value = true
      }
    }
    function mouseUpGrid(e: Event) {
      e.preventDefault()
      e.stopImmediatePropagation()
      paintActive.value = false
    }

    function mouseLeaveGrid(e: Event) {
      e.preventDefault()
      e.stopImmediatePropagation()
      paintActive.value = false
    }

    function availabilityFromTarget(day: number, hour: number) {
      paintModeAvaiable.value = !formattedData[day].availability[hour]
      formattedData[day].availability[hour] = !formattedData[day].availability[hour]
    }

    function formatData() {
      Object.keys(dayparting_data).forEach((day: any) => {
        formattedData[day] = {
          availability: dayparting_data[day].map((hour: any) => !!hour),
          focus: false,
        }
      })
    }

    function generateLabelsHours(): LabelHours {
      const labels: any = {}
      for (let i = 0; i < 24; i++) {
        labels[i] = {
          label: i,
          focus: false,
        }
      }
      return labels
    }

    function toogleAll() {
      const allSelected = Object.values(formattedData).every((day: any) => day.availability.every((hour: any) => hour))
      Object.values(formattedData).forEach((day: any) => {
        day.availability = day.availability.map((hour: any) => !allSelected)
      })
    }

    watch(formattedData, (data) => {
      debounce(() => {
        const data: any = {}
        Object.keys(formattedData).forEach((day: any) => {
          data[day] = formattedData[day].availability.map((hour: any) => (hour ? 1 : 0))
        })
        emit('updateDayparting', data)
      }, 1000)()
    })

    onMounted(() => {
      Object.assign(formattedData, formatData())
      Object.assign(labelHours, generateLabelsHours())
    })
    return {
      formatData,
      formattedData,
      hoverCell,
      leaveHoverCell,
      labelHours,
      labelFocusClass,
      switchCellState,
      selectAllFromHour,
      selectAllFromDay,
      mouseDownGrid,
      mouseUpGrid,
      mouseClickGrid,
      mouseLeaveGrid,
      timePresetsLabels,
      selectAllFromHourPreset,
      generateLabelsHours,
      dayparting_data,
      timeout,
      toogleAll,
    }
  },
})
</script>

<style lang="scss" scoped>
.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}
.container {
  font-size: 14px;
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 768px) {
  .container {
    display: none;
  }
}

.grid-rows {
  display: grid;
  grid-template-rows: repeat(7, 1fr);
  gap: 2px;
  cursor: pointer;
}
.grid-element-row {
  color: black;
}

.grid-cols {
  width: full;
  display: grid;
  grid-template-columns: repeat(25, 1fr);
  gap: 2px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.grid-cols-hours-preset {
  width: full;
  display: grid;
  grid-template-columns: 40px 1fr 1fr 1fr 1fr;
  gap: 2px;
  cursor: pointer;
}

.grid_element_col {
  color: black;
  padding: 5px;
  cursor: crosshair;
}

.grid_element_col:hover {
  -webkit-box-shadow: inset 0px 0px 0px 2px rgb(120, 120, 120);
  -moz-box-shadow: inset 0px 0px 0px 2px rgb(120, 120, 120);
  box-shadow: inset 0px 0px 0px 2px rgb(120, 120, 120);
}

.grid_element_days_label {
  width: 40px;
}
.grid_element_hours_label {
  text-align: center;
}

.grid_element_days_label:hover {
  color: #017bfe;
  -webkit-box-shadow: inset 0px 0px 0px 0.5px #017bfe;
  -moz-box-shadow: inset 0px 0px 0px 0.5px #017bfe;
  box-shadow: inset 0px 0px 0px 0.5px #017bfe;
}
.grid_element_hours_label,
.grid_element_hours_preset,
.grid_element_days_label {
  border: 0.5px solid #d3d3d3;
  padding: 5px;
  overflow: hidden;
  min-width: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.grid_element_hours_preset:hover,
.grid_element_hours_label:hover,
.grid_element_days_label:hover {
  color: #017bfe;
  -webkit-box-shadow: inset 0px 0px 0px 0.5px #017bfe;
  -moz-box-shadow: inset 0px 0px 0px 0.5px #017bfe;
  box-shadow: inset 0px 0px 0px 0.5px #017bfe;
}

.label_focus {
  color: #017bfe;
  -webkit-box-shadow: inset 0px 0px 0px 0.5px #017bfe;
  -moz-box-shadow: inset 0px 0px 0px 0.5px #017bfe;
  box-shadow: inset 0px 0px 0px 0.5px #017bfe;
}

.not_selected_cell {
  background-color: #017bfe;
}
.selected_cell {
  background-color: white;
}

.container-legend {
  display: flex;
  gap: 20px;
}

.container-legend .legend {
  display: flex;
  gap: 5px;
  align-items: center;
}

.container-legend .box {
  width: 20px;
  height: 20px;
  border: 1px solid #d3d3d3;
}
</style>
