<template>
  <div class="content">
    <div class="wrapper">
      <div class="header">
        <img class="logo" :src="logo" />
      </div>
      <div class="form-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts" scoped>
import { defineComponent } from 'vue'
import logo from '../assets/logo-bk.png'

export default defineComponent({
  name: 'CenteredForm',
  setup() {
    return { logo }
  },
})
</script>

<style lang="scss" scoped>
.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  font-family: proxima nova, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  background-color: #f9f9f9;
  min-width: 100%;
  min-height: 100%;
  color: #777;
  overflow: auto;

  display: flex;
}

.wrapper {
  background-color: #fff;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd #ddd #d8d8d8;
  box-shadow: 0 2px 0 hsl(0deg 0% 69% / 12%);
  margin: 100px auto 8px;
  width: 400px;
  height: min-content;
}

.header {
  padding: 30px 90px;
  position: relative;
  border-bottom: 1px solid #ddd;
  z-index: 10;
}

.logo {
  display: block;
  margin: 0 auto;
  height: 2rem;
}

.form-content {
  padding: 20px 42px;
}

@media only screen and (max-height: 750px) {
  .wrapper {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
}

@media only screen and (max-width: 600px) {
  .wrapper {
    width: 100%;
    border: none;
    margin: 0 auto;
    padding-top: 100px;
    height: auto;
    flex: 1;
  }

  .form-content {
    max-width: 400px;
    margin: auto;
    padding: 20px 10px;
  }

  .header {
    max-width: 400px;
    margin: auto;
    padding: 30px 0;
  }
}

//100px auto 8px
</style>
