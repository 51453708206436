<template>
  <div class="d-flex flex-column flex-grow-1 position-relative">
    <PageHeader>
      <template #default>Search Results</template>
      <template #subtitle>{{ $route.params.query }}</template>
    </PageHeader>
    <div v-if="results" class="content flex-grow-1">
      <div class="search-results white flex-grow-1">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <button class="nav-link" :class="{ active: tab === 'announcements' }" @click="tab = 'announcements'">
              Announcements ({{ results.announcements?.length ?? 0 }})
            </button>
          </li>
          <li class="nav-item">
            <button
              class="nav-link"
              :class="{ active: tab === 'points_of_interest' }"
              @click="tab = 'points_of_interest'"
            >
              Points of Interest ({{ results['points_of_interest']?.length ?? 0 }})
            </button>
          </li>
          <li class="nav-item">
            <button class="nav-link" :class="{ active: tab === 'components' }" @click="tab = 'components'">
              Components ({{ results.components?.length ?? 0 }})
            </button>
          </li>
          <li class="nav-item">
            <button class="nav-link" :class="{ active: tab === 'media' }" @click="tab = 'media'">
              Media ({{ results.media?.length ?? 0 }})
            </button>
          </li>
        </ul>
        <div class="group-results bg-white p-3">
          <div class="fs-4 p-2 mb-2 bg-light">
            <span class="text-primary font-w700">{{ results[tab].length }}</span>
            {{ tab }} found for
            <mark class="text-danger">{{ $route.params.query }}</mark>
          </div>
          <hr />
          <transition appear name="fade" mode="out-in">
            <template v-if="tab === 'media'">
              <MediaViewer
                :media="results[tab]"
                :showFilters="false"
                :canEdit="false"
                :canUpload="false"
                :canSelect="false"
              />
            </template>
            <Table
              v-else
              :title="`Search results for ${tab}`"
              :key="tab"
              :headers="headers[tab]"
              :rows="results[tab]"
              @view="handle('view', $event)"
              @view-tab="handle('view-tab', $event)"
              view
            />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watchEffect } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { search } from '@/store/cms'
import Table from '@/components/misc/Table.vue'
import MediaViewer from '@/components/media/MediaViewer.vue'
import { PageHeader } from '@avriopolis/common/components'

import * as headers from '@/utils/headers'

export default defineComponent({
  name: 'SearchResults',
  emits: ['loading'],
  components: {
    PageHeader,
    Table,
    MediaViewer,
  },
  setup(props, { emit }) {
    const router = useRouter()
    const results = ref<SearchResults>()
    const route = useRoute()
    const tab = ref<'announcements' | 'media' | 'points_of_interest' | 'components'>('announcements')

    async function updateData() {
      emit('loading', true)
      results.value = await search(route.params.query.toString())
      emit('loading', false)
    }

    function handle(event: string, id: number) {
      switch (event) {
        case 'view':
          router.push({ name: `view-${tab.value}`, params: { id } })
          break
        case 'view-tab':
          const { fullPath } = router.resolve({ name: `view-${props.name}`, params: { id } });
          window.open(fullPath, "_blank");
          break
      }
    }

    watchEffect(() => {
      if (route.params.query) {
        updateData()
      }
    })

    updateData()

    return {
      results,
      tab,
      handle,
      headers: {
        media: headers.MEDIA,
        points_of_interest: headers.POINTS_OF_INTEREST,
        components: headers.COMPONENTS,
        announcements: headers.ANNOUNCEMENTS,
      },
    }
  },
})
</script>
