import i18n from '@avriopolis/common/i18n'
import Swal from 'sweetalert2'
import { cloneDeep } from 'lodash'

export function isValueLocalised(value: any) {
  if (Array.isArray(value) && value.length > 0) {
    const translations = value as Array<any>
    const item = translations[0]
    if (item.hasOwnProperty('lang') && item.hasOwnProperty('value')) {
      return true
    }
  }
  return false
}

export function getLocalisedValue(translations: Array<any>, _locale: string): { lang: string; value: any } {
  if (!translations) {
    translations = [{ lang: _locale, value: '' }]
  }

  const translation = translations.find((v) => v.lang === _locale)
  if (translation) {
    return translation
  } else {
    for (let { lang, value } of translations as any[]) {
      if (i18n.global.availableLocales.includes(lang)) {
        return { lang, value }
      }
    }
    return { lang: translations[0].lang, value: i18n.global.t('language.missing') }
  }
}

export async function updateLocalisedValue(
  _translations: Array<any>,
  _locale: string,
  value: any,
): Promise<{ lang: string; value: any } | undefined> {
  if (!i18n.global.availableLocales.includes(_locale)) {
    return
  }

  if (!_translations) {
    _translations = [{ lang: _locale, value: '' }]
  }

  const translations = cloneDeep(_translations)
  const translation = translations.find((v) => v.lang === _locale)
  if (translation) {
    translation.value = value
    return { lang: _locale, value: translations }
  } else {
    return new Promise((res) => {
      Swal.fire({
        title: `No translation exists for ${_locale}, do you want to create one?`,
        icon: 'question',
        confirmButtonColor: '#0d6efd',
        showDenyButton: true,
      }).then((result: any) => {
        if (result.isConfirmed) {
          translations.push({ lang: _locale, value: '' })
          return res({ lang: _locale, value: translations })
        } else {
          res(undefined)
        }
      })
    })
  }
}
