<template>
  <div class="wysiwyg-wrapper" :class="{ readonly }">
    <div style="min-height: 200px; max-height: 400px" class="overflow-auto" ref="container" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watchEffect } from 'vue'

import Quill from 'quill'
import 'quill/dist/quill.snow.css'

export default defineComponent({
  name: 'WYSIWYG',
  props: {
    value: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const container = ref()

    let editor: any = undefined

    watchEffect(() => {
      if (props.value !== null && props.value !== undefined && editor) {
        editor.enable()
        editor.setText('')
        editor.pasteHTML(0, props.value, 'user')
        if (props.readonly) editor.disable()
      }
    })

    onMounted(() => {
      editor = new Quill(container.value, {
        debug: 'warn',
        placeholder: 'Content...',
        value: 'Test',
        theme: 'snow',
      })

      editor.on('text-change', function () {
        const newHtml = `${editor.root.innerHTML}`
        if (newHtml !== props.value) {
          emit('input', newHtml)
        }
      })

      editor.enable()
      editor.pasteHTML(0, props.value, 'user')
      if (props.readonly) editor.disable()
    })

    return { container }
  },
})
</script>

<style lang="scss">
.wysiwyg-wrapper {
  &.readonly {
    background: #e9ecef;
    > .ql-toolbar {
      padding: 0;
      height: 0;
      border-bottom: 0;
      * {
        display: none;
      }
    }
  }
}
</style>
