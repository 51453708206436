<template>
  <div v-if="announcement" class="d-flex flex-column flex-grow-1 position-relative">
    <PageHeader>
      <template #default>
        <div class="d-flex gap-2">
          <router-link class="btn btn-sm d-inline-flex m-0 p-0" :to="{ name: 'view-announcements' }">
            <span class="material-icons me-2">undo</span>
          </router-link>
          Announcement
        </div>
      </template>
      <template #right>
        <text-input
          class="border"
          inputClass="fs-4"
          style="flex: 1 0"
          :value="newData.title"
          @change="updateValue('Announcements', announcement, newData, { title: $event })"
        />
      </template>
    </PageHeader>
    <transition appear name="fade">
      <div
        class="content d-flex flex-column flex-xl-row-reverse flex-grow-1 position-relative gap-3 align-items-xl-start"
      >
        <div style="min-width: 290px; top: 1rem" class="sticky-xl-top">
          <div class="d-flex flex-column p-3 border rounded-3 bg-white mb-2">
            <div class="d-flex justify-content-between align-items-center gap-5" data-v-19a25a2a="">
              <span class="fs-7 fw-bolder text-muted">Status</span>
              <select
                class="form-select"
                :value="newData.status"
                @input="updateValue('Announcements', announcement, newData, { status: $event.target.value })"
              >
                <option value="published">Published</option>
                <option value="draft">Draft</option>
                <option value="archived">Archived</option>
              </select>
            </div>
            <hr style="margin: 0.5rem 0" />
            <properties
              :object="{
                Name: announcement.title,
                Type: announcement.type,
                Source: announcement.source,
                'Date Created': new Date(announcement.date_created).toLocaleString(),
                'Last Updated': new Date(announcement.date_updated).toLocaleString(),
              }"
            />
            <hr />
            <div class="d-flex align-items-center" style="width: 100%; overflow: hidden">
              <CSVEditor
                class="flex-grow-1"
                type="Tag"
                :values="newData.tags ?? []"
                @input="updateValue('Announcements', announcement, newData, { tags: $event })"
              />
            </div>
          </div>
          <div class="d-flex justify-content-between gap-2">
            <div class="dropdown">
              <button
                class="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Add Field
              </button>
              <ul class="dropdown-menu overflow-auto" style="max-height: 300px">
                <template v-for="group in grouped_fields" :key="group.name">
                  <li>
                    <h5 class="m-0 px-2">{{ group.name }}</h5>
                    <div v-for="field in group.fields" :key="field.id">
                      <button class="dropdown-item" @click="addField(field.id)">
                        {{ field.name }}
                      </button>
                    </div>
                  </li>
                </template>
              </ul>
            </div>
            <div class="d-flex gap-2">
              <router-link
                class="btn btn-success btn-sm d-flex"
                :to="{ name: 'view-announcements', params: { save: true } }"
              >
                <span class="material-icons me-2">save</span>
                <span class="fs-6">Save</span>
              </router-link>
            </div>
          </div>
        </div>

        <fields-edit :fields="announcement.fields" @update="updateEditedField" />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, reactive } from 'vue'
import { useRoute } from 'vue-router'
import axios from '@/api'

import { PageHeader, CSVEditor } from '@avriopolis/common/components'
import Auth from '@/store/auth'
import LocalisedValue from '@/components/misc/LocalisedValue.vue'
import FieldsEdit from '@/components/fields/FieldsEdit.vue'
import Properties from '@/components/misc/Properties.vue'
import WYSIWYG from '@/components/inputs/WYSIWYG.vue'
import TextInput from '@/components/inputs/TextInput.vue'
import CategoryList from '@/components/misc/CategoryList.vue'
import { pushChange } from '@/store/changes'
import { updateValue, groupFields, updateField } from '@/utils/values'

export default defineComponent({
  name: 'Edit_Points_of_Interest',
  emits: ['loading'],
  components: {
    PageHeader,
    LocalisedValue,
    FieldsEdit,
    Properties,
    WYSIWYG,
    TextInput,
    CSVEditor,
    CategoryList,
  },
  setup(_props, { emit }) {
    const route = useRoute()
    const announcement = ref<Announcement>()
    const fields = ref<Field[]>()
    const newData = reactive({
      title: announcement.value?.title,
      status: announcement.value?.status,
      tags: announcement.value?.tags,
    })

    const fetchData = async () => {
      const response = await axios.get(`/items/announcements/${route.params.id}?fields=*,fields.*,fields.fields_id.*`)
      announcement.value = response?.data?.data

      Object.assign(newData, {
        title: announcement.value?.title,
        status: announcement.value?.status,
        tags: announcement.value?.tags,
      })

      const response_2 = await axios.get('/items/fields')
      fields.value = response_2?.data?.data
    }

    const grouped_fields = computed(() => {
      if (fields.value) {
        return groupFields(announcement.value, fields.value)
      }
      return []
    })

    async function updateData() {
      emit('loading', true)
      try {
        await fetchData()
      } catch (e) {}
      emit('loading', false)
    }

    async function addField(fields_id: number) {
      await axios.post('items/announcements_fields', {
        announcements_id: announcement.value?.id,
        fields_id,
        locked: false,
      })
      updateData()
    }

    async function updateEditedField(field: { id: number; value: any }) {
      if (announcement.value) {
        return await updateField('Announcements_Field', announcement.value, field)
      }
    }

    updateData()

    return {
      announcement,
      grouped_fields,
      updateEditedField,
      pushChange,
      addField,
      updateValue,
      newData,
      user: Auth.user,
    }
  },
  beforeRouteEnter(to, _from, next) {
    if (!to.params.tab) {
      return next({ ...to, params: { ...to.params, tab: 'info' } })
    }
    next()
  },
})
</script>
