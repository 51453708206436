export const monaco_kiosk_names: { GLOOH_ID: string; NAME: string }[] = [
  {
    GLOOH_ID: 'CCF_MC_01',
    NAME: 'ALBERT_2',
  },
  {
    GLOOH_ID: 'CCF_MC_02',
    NAME: 'FONTVIELLE_CENTRE_COMMERCIAL',
  },
  {
    GLOOH_ID: 'CCF_MC_03',
    NAME: 'PORT_DE_FONVIEILLE',
  },
  {
    GLOOH_ID: 'CCF_MC_04',
    NAME: 'STADE_LOUIS_II',
  },
  {
    GLOOH_ID: 'CCF_MC_05',
    NAME: 'BOSIO_AVAL',
  },
  {
    GLOOH_ID: 'CCF_MC_06',
    NAME: 'VILLA_PALOMA',
  },
  {
    GLOOH_ID: 'BRN07COS001MC98000',
    NAME: 'PLAGES',
  },
  {
    GLOOH_ID: 'CCF_MC_07',
    NAME: 'PLAGES',
  },
  {
    GLOOH_ID: 'CCF_MC_08',
    NAME: 'HONOREE_II',
  },
  {
    GLOOH_ID: 'CCF_MC_09',
    NAME: 'PLACE_DARMES_MCB',
  },
  {
    GLOOH_ID: 'CCF_MC_10',
    NAME: 'PLACE_DARMES_CIII',
  },
  {
    GLOOH_ID: 'CCF_MC_11',
    NAME: 'MONACO_VILLE_ABRI_SIMPLE',
  },
  {
    GLOOH_ID: 'CCF_MC_12',
    NAME: 'MONACO_VILLE_ABRI_DOUBLE',
  },
  {
    GLOOH_ID: 'CCF_MC_13',
    NAME: 'PLACE_DARMES_PORT_ALBATROS',
  },
  {
    GLOOH_ID: 'CCF_MC_14',
    NAME: 'PRINCESSE_STEPHANIE_DIRECT_FONTVIEILLE',
  },
  {
    GLOOH_ID: 'CCF_MC_15',
    NAME: 'PRINCESSE_STEPHANIE_TERRE_PLEIN_FANGIO',
  },
  {
    GLOOH_ID: 'CCF_MC_16',
    NAME: 'PRINCESSE_ANTOINETTE_INTERURBAIN_DOUBLE',
  },
  {
    GLOOH_ID: 'CCF_MC_17',
    NAME: 'PLACE_SAINTE_DEVOTTE',
  },
  {
    GLOOH_ID: 'CCF_MC_18',
    NAME: 'PONT_SAINTE_DEVOTE_MONTE',
  },
  {
    GLOOH_ID: 'CCF_MC_19',
    NAME: 'ARRET_ROQUEVILLE_DESCENTE',
  },
  {
    GLOOH_ID: 'CCF_MC_20',
    NAME: 'MONTECARLO_TOURISME',
  },
  {
    GLOOH_ID: 'CCF_MC_21',
    NAME: 'SQUARE_BEAUMARCHEAIS_MONTEE',
  },
  {
    GLOOH_ID: 'CCF_MC_22',
    NAME: 'SQUARE_BEAUMARCHEAIS_DESCENTE',
  },
  {
    GLOOH_ID: 'CCF_MC_23',
    NAME: 'TERRASSES_DU_CASINO',
  },
  {
    GLOOH_ID: 'CCF_MC_24',
    NAME: 'KENNEDY',
  },
  {
    GLOOH_ID: 'CCF_MC_25',
    NAME: 'LOUIS_AUREGLIA',
  },
  {
    GLOOH_ID: 'CCF_MC_26',
    NAME: 'PLACE_CASINO',
  },
  {
    GLOOH_ID: 'CCF_MC_27',
    NAME: 'CREMAILLERE',
  },
  {
    GLOOH_ID: 'CCF_MC_28',
    NAME: 'PORTIER_AVAL',
  },
  {
    GLOOH_ID: 'CCF_MC_29',
    NAME: 'GRIMALDI_FORUM_VILLA_SAUBER_AVAL',
  },
  {
    GLOOH_ID: 'CCF_MC_30',
    NAME: 'GRIMALDI_FORUM_VILLA_SAUBER_AMONT',
  },
  {
    GLOOH_ID: 'CCF_MC_31',
    NAME: 'PLAGES_AMONT_ESTORIL',
  },
  {
    GLOOH_ID: 'CCF_MC_32',
    NAME: 'SEA_CLUB_AVAL',
  },
  {
    GLOOH_ID: 'CCF_MC_33',
    NAME: 'PLACE_DES_MOULINS_AMONT',
  },
  {
    GLOOH_ID: 'CCF_MC_34',
    NAME: 'ROSERAIE',
  },
  {
    GLOOH_ID: 'CCF_MC_35',
    NAME: 'GARE_SNCF_EXTRIEUR',
  },
  {
    GLOOH_ID: 'CCF_MC_36',
    NAME: 'PRINCESSE_CAROLINE_RUE_PIETONNE',
  },
  {
    GLOOH_ID: 'CCF_MC_37',
    NAME: 'JARDIN_EXOTIQUE',
  },
  {
    GLOOH_ID: 'CCF_MC_38',
    NAME: 'PLACE_DARMES_MONTE_MONACOVILLE',
  },
  {
    GLOOH_ID: 'CCF_MC_39',
    NAME: 'GARE_SNCF_INTERIEUR',
  },
  {
    GLOOH_ID: 'CCF_MC_40',
    NAME: 'GARE_MARITIME_INTERIEUR\n?? à déterminer après déplacement ??',
  },
  {
    GLOOH_ID: 'CCF_MC_41',
    NAME: 'PARKING_DES_PECHEURS',
  },
  {
    GLOOH_ID: 'CCF_MC_42',
    NAME: 'EGLISE_ST_CHARLES',
  },
  {
    GLOOH_ID: 'CCF_MC_43',
    NAME: 'COULOIR_GARE',
  },
  {
    GLOOH_ID: 'CCF_MC_44',
    NAME: 'TUNNEL_DU_GRAND_PRIX',
  },
  {
    GLOOH_ID: 'CCF_MC_45',
    NAME: 'ESPLANADE_DU_LARVOTTO',
  },
  {
    GLOOH_ID: 'CCF_MC_TEST',
    NAME: 'BORNE_DE_TEST',
  },
]
