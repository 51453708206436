<template>
  <template v-if="loading">
    <div class="d-flex flex-column flex-grow-1 gap-2 mb-5 border rounded-3 bg-white overflow-hidden position-relative" style="height: 10rem;">
      <transition appear name="fade" mode="out-in">
        <Spinner fill_parent />
      </transition>
    </div>
  </template>
  <template v-else>
    
  <div class="d-flex flex-column flex-grow-1 gap-2 mb-5 border rounded-3 bg-white overflow-hidden">
    <div v-for="(field, i) in fields" :key="i" class="group d-flex flex-column px-3">
      <template v-if="field.meta.type === 'localised-media'">
        <div class="d-flex align-items-center justify-content-between">
          <h6 class="border-bottom py-2 m-0 text-capitalize">{{ field.key }}</h6>
          <h6 class="border-bottom py-2 m-0 text-capitalize">{{ field.key }}</h6>
        </div>
        <div class="d-flex flex-column pb-3">
          <div class="d-flex justify-content-between align-items-center pt-3" v-for="locale in $i18n.availableLocales">
            <span class="text-uppercase">{{ locale }}</span>
            <div class="d-flex gap-3 align-items-center">
              <button
                v-if="!!(field.value ?? {})[locale]"
                class="btn btn-sm btn-danger"
                @click="removeLocalisedMedia(field, locale)"
              >
                <span class="material-icons">delete</span>
              </button>
              <media-selector
                single
                :id="locale"
                :media="media.filter((m) => m.tags?.includes(locale))"
                @selected="localisedMediaSelected({ ...field.meta.config, tag: locale }, $event[0])"
              />
            </div>
          </div>
          <media-cropper
            v-if="cropping"
            :file="cropping.file"
            :options="cropping.options"
            @cancel="cropping = undefined"
            @cropped="localisedMediaCropped(field, $event)"
          />
        </div>
      </template>
      <template v-else>
        <h6 class="border-bottom py-2 m-0 text-capitalize">{{ field.meta.title ?? field.key }}</h6>
        <template v-if="field.meta.type === 'csv'">
          <CSVEditor :type="field.key" :values="field.value ?? []" @input="updateValues(field, $event)" />
        </template>
        <template v-else-if="field.meta.type === 'enum'">
          <select
            class="form-select mb-2"
            aria-label="Default select example"
            :value="field.value"
            @input="updateValues(field, $event.target.value)"
          >
            <option v-for="option in field.meta.values" :key="option" :value="option">
              {{ option }}
            </option>
          </select>
        </template>
        <template v-else-if="field.meta.type === 'localised-text' || field.meta.type === 'text'">
          <text-input
            :localised="field.meta.type === 'localised-text'"
            class="border mb-2"
            :value="field.value"
            @change="updateValues(field, $event)"
          />
        </template>
        <template v-else-if="field.meta.type === 'localised-wysiwyg' || field.meta.type === 'wysiwyg'">
          <WYSIWYG
            :localised="field.meta.type === 'localised-wysiwyg'"
            :id="field.key"
            height="auto"
            :value="field.value"
            @change="updateValues(field, $event)"
          />
        </template>
        <template v-else-if="field.meta.type === 'media'">
          <div class="d-flex flex-column mb-3 pt-2 gap-3">
            <div
              class="d-flex align-items-center justify-content-between"
              v-for="file in field.meta.values"
              :key="file"
            >
              <span>{{ file.tag }}</span>
              <media-selector
                single
                :id="file.tag"
                :media="media.filter((m) => m.tags?.includes(file.tag))"
                @selected="mediaSelected(file, $event)"
              />
            </div>
            <media-cropper
              v-if="cropping"
              :file="cropping.file"
              :options="cropping.options"
              @cancel="cropping = undefined"
              @cropped="mediaCropped"
            />
          </div>
        </template>
        <template v-else-if="field.meta.type === 'medias'">
          <div class="d-flex flex-column mb-3 pt-2 gap-3">
            <div
              class="d-flex align-items-center justify-content-between"
              v-for="file in field.meta.values"
              :key="file"
            >
              <span>{{ file.tag }}</span>
              <media-selector
                :id="file.tag"
                :media="media.filter((m) => m.tags?.includes(file.tag))"
                @selected="mediasSelected(file, $event)"
              />
            </div>
          </div>
        </template>
        <template v-else-if="field.meta.type === 'number'">
          <text-input
            type="number"
            :min="field.meta.min"
            :max="field.meta.max != -1 ? field.meta.max : undefined"
            class="border mb-2"
            :value="field.value"
            @change="updateValues(field, $event)"
          />
        </template>
        <template v-else-if="field.meta.type === 'category_ids'">
          <custom-select
            :placeholder="`Select Categories`"
            :options="categories"
            :selected="field.value"
            @selected="toggleCategory(i, $event.id)"
          >
            <template #default="{ option }">
              <localised-value :value="option?.name" />
            </template>
          </custom-select>
        </template>
        <template v-else>
          <span class="mb-2">Unsupported type: {{ field.meta.type }}</span>
        </template>
      </template>
    </div>
  </div>
  </template>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType, ref } from 'vue'
import { cloneDeep } from 'lodash'

import axios from '@/api'
import project from '@/store/project'

import WYSIWYG from '@/components/inputs/WYSIWYG.vue'
import TextInput from '@/components/inputs/TextInput.vue'
import LocalisedValue from '@/components/misc/LocalisedValue.vue'
import MediaSelector from '@/components/inputs/MediaSelector.vue'
import MediaCropper from '@/components/media/MediaCropper.vue'
import { CSVEditor, Spinner } from '@avriopolis/common/components'
import CustomSelect from '@/components/inputs/CustomSelect.vue'

type MetaField = {
  key: string
  meta: any
  value: any
}

export default defineComponent({
  name: 'MetadataEdit',
  emits: ['update', 'media', 'medias', 'localisedMedia'],
  props: {
    template: {
      type: Object as PropType<{ [key: string]: any }>,
      required: true,
    },
    values: {
      type: Object as PropType<{ [key: string]: any }>,
      required: true,
    },
    media: {
      type: Array as PropType<MediaRaw[]>,
      default: [],
    },
  },
  components: {
    WYSIWYG,
    TextInput,
    LocalisedValue,
    CSVEditor,
    MediaSelector,
    MediaCropper,
    Spinner,
    CustomSelect
  },
  setup(props, { emit }) {
    const values = ref<{ [id: string]: any }>({})
    const cropping = ref<{ file: DirectusFile; options: any }>()
    
    const loading = ref(true)
    const categories = ref<Category[]>()

    const metadata = props.template
    for (let field of Object.keys(metadata)) {
      values.value[field] = cloneDeep(props.values[field])
    }

    const fields = computed<MetaField[]>(() => {
      return Object.keys(metadata).map((k) => ({
        key: k,
        meta: metadata[k],
        value: values.value[k],
      }))
    })

    function updateValues(field: MetaField, value: any) {
      console.log(field.key, value)
      values.value[field.key] = value
      emit('update', { id: field.key, value })
    }

    function localisedMediaSelected(fileMeta: any, file: DirectusFile) {
      cropping.value = { file, options: fileMeta }
    }

    function localisedMediaCropped(field: MetaField, file: DirectusFile) {
      console.log(field, file)
      const tag = cropping.value?.options.tag
      emit('localisedMedia', { tag, directus_files_id: file.id })
      cropping.value = undefined
      values.value[field.key] = Object.assign({}, values.value[field.key], { [tag]: true })

      emit('update', { id: field.key, value: values.value[field.key] })
    }

    function removeLocalisedMedia(field: MetaField, locale: string) {
      values.value[field.key] = Object.assign({}, values.value[field.key], { [locale]: undefined })
      emit('localisedMedia', { tag: locale, directus_files_id: undefined })
      emit('update', { id: field.key, value: values.value[field.key] })
    }

    function mediaSelected(fileMeta: any, files: DirectusFile[]) {
      if (fileMeta.format?.includes('image') && files.length === 1) {
        cropping.value = { file: files[0], options: fileMeta }
      } else {
        emit('media', { tag: fileMeta.tag, directus_files_id: files.map((f) => f.id) })
      }
    }

    function mediasSelected(fileMeta: any, files: DirectusFile[]) {
      emit('medias', { tag: fileMeta.tag, directus_files_id: files.map((f) => f.id) })
    }

    function mediaCropped(file: DirectusFile) {
      const tag = cropping.value?.options.tag
      emit('media', { tag, directus_files_id: file.id })
      cropping.value = undefined
    }

    function toggleCategory(fieldIndex: number, categoryId: number) {
      const field = fields.value[fieldIndex]
      const value = values.value[field.key];

      const categories = value ?? [];
      if (categories.includes(categoryId)) {
        categories.splice(categories.indexOf(categoryId), 1);
      } else {
        categories.push(categoryId);
      }

      updateValues(field, categories)
    }

    const fetchData = async () => {
      const _categories = (await axios.get(`/items/categories/?fields=id,name,description,slug,icon&filter[projects_id]=${project.value.active?.id}`)).data.data;
      categories.value = _categories;
      loading.value = false
    }

    onMounted(() => {
      fetchData()
    })

    return {
      fields,
      updateValues,
      mediaSelected,
      cropping,
      mediaCropped,
      mediasSelected,
      localisedMediaCropped,
      localisedMediaSelected,
      removeLocalisedMedia,
      categories,
      toggleCategory,
      loading
    }
  },
})
</script>
