<template>
  <div class="wysiwyg-wrapper" :class="{ [locale]: true, locked }" :id="id">
    <div ref="editorRef"></div>
    <teleport v-if="loaded && isLocalised" :to="`#${id} .tui-editor-defaultUI-toolbar`">
      <language-selector :locale="locale" icons @locale="locale = $event" />
    </teleport>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'

import Editor from '@toast-ui/editor'
import '@toast-ui/editor/dist/toastui-editor-only.css'

import LanguageSelector from '@/components/inputs/LanguageSelector.vue'
import { defualtToolbarItems } from '@/utils/toolbar'
import { isValueLocalised, getLocalisedValue, updateLocalisedValue } from '@/utils/localisation'

export default defineComponent({
  name: 'WYSIWYG',
  emits: ['change'],
  props: {
    id: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      default: 'auto',
    },
    value: {
      type: [Object, String, Number, Array],
      default: undefined as unknown,
    },
    localised: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LanguageSelector,
  },
  setup(props, { emit }) {
    const i18n = useI18n()
    const editorRef = ref()
    const editor = ref<Editor>()
    const locale = ref(i18n.locale.value)
    const defaultValue = ref('')
    const loaded = ref(false)

    const isLocalised = computed(() => {
      return props.localised || isValueLocalised(props.value)
    })

    const localisedValue = computed(() => {
      if (isLocalised.value) {
        const localisedValue = getLocalisedValue(props.value as Array<any>, locale.value)
        if (localisedValue) {
          locale.value = localisedValue.lang
          return localisedValue.value
        }
      } else {
        return props.value ?? ''
      }
    })

    const locked = computed(() => {
      return !i18n.availableLocales.includes(locale.value)
    })

    watchEffect(() => {
      const wwEditor = (editor.value as any)?.wwEditor!
      if (wwEditor) {
        if (wwEditor.getValue() == localisedValue.value) return
        wwEditor.readySilentChange()
        wwEditor.setValue(localisedValue.value)
      }
    })

    async function updateAndEmit(_locale: string, value: any) {
      const translations = props.value as Array<any>
      const updatedValue = await updateLocalisedValue(translations, _locale, value)
      if (updatedValue) {
        emit('change', updatedValue.value)
        locale.value = updatedValue.lang
      }
    }

    onMounted(() => {
      editor.value = new Editor({
        el: editorRef.value,
        height: props.height,
        initialEditType: 'wysiwyg',
        usageStatistics: false,
        hideModeSwitch: true,
        initialValue: localisedValue.value, //props.value,
        toolbarItems: [...defualtToolbarItems],
      })

      editor.value.on('contentChangedFromWysiwyg', async (e) => {
        const value = e.getValue()
        if (!defaultValue.value) {
          defaultValue.value = value
        } else {
          const hasChanged = value !== defaultValue.value
          const newValue = hasChanged ? value : localisedValue.value

          if (isLocalised.value) {
            updateAndEmit(locale.value, newValue)
          } else {
            emit('change', newValue)
          }
        }
      })

      editor.value.on('command', async (e) => {
        switch (e) {
          case 'fr-FR':
          case 'en-US':
          case 'nl-NL':
            updateAndEmit(e, getLocalisedValue(props.value as Array<any>, e).value)
            break
        }
      })

      loaded.value = true
    })

    return { editorRef, editor, locale, locked, loaded, isLocalised }
  },
})
</script>

<style lang="scss">
.wysiwyg-wrapper {
  .te-editor {
    max-height: 300px !important;
  }

  .tui-editor-contents {
    min-height: 100px !important;
    padding: 16px !important;
    background: #f8f9fa;

    &:focus {
      background: #e0e5e8;
    }
  }

  .te-toolbar-section {
    height: auto;
  }

  .tui-editor-defaultUI-toolbar {
    display: flex;
    padding: 0 5px;
    flex-wrap: wrap;
    height: auto;

    > * {
      float: none;
    }

    .tui-toolbar-icons:not(.tui-language) {
      min-width: 22px;
    }

    .tui-spacer {
      flex-grow: 1;
    }

    .tui-fr,
    .tui-en,
    .tui-nl {
      width: 32px;
      min-width: 32px;
      background-size: contain;
      background-repeat: no-repeat;

      opacity: 0.5;
      filter: grayscale(0.8);
    }

    .tui-fr {
      background-image: url(@avriopolis/common/assets/flags/fr-FR.svg);
    }

    .tui-en {
      background-image: url(@avriopolis/common/assets/flags/en-US.svg);
    }

    .tui-nl {
      background-image: url(@avriopolis/common/assets/flags/nl-NL.svg);
    }
  }

  &.en-US .tui-en,
  &.fr-FR .tui-fr,
  &.nl-NL .tui-nl {
    opacity: 1;
    filter: none;
  }

  &.locked {
    .tui-toolbar-icons:not(.tui-language) {
      user-select: none;
      pointer-events: none;
    }

    .te-editor-section {
      position: relative;
      opacity: 0.5;
      user-select: none;

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2500;

        content: ' ';
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
}
</style>
