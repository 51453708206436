<template>
  <div class="mapview flex-grow-1" :class="{ loaded: mapLoaded }" ref="mapRef" />
  <div
    class="position-absolute d-flex w-100 h-100 left-0 top-0 justify-content-center align-items-center"
    :style="{ pointerEvents: 'none' }"
  >
    <spinner v-if="!mapLoaded" :size="100" />
  </div>
</template>

<script lang="ts" scoped>
import { defineComponent, onMounted, ref, PropType, watchEffect, reactive } from 'vue'

import marker from '../assets/marker.png'
import Spinner from './Spinner.vue'

import mapboxgl, { GeoJSONSource } from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

mapboxgl.accessToken = 'pk.eyJ1IjoicmFuc2V1ciIsImEiOiJja2xhb2E3NmMzbTd1MnJxbzZkdnUzcTNyIn0.DeAM00VdCGe-eLCVhlB8mA'

export type Marker = {
  lat: number
  lng: number
}

export default defineComponent({
  name: 'MapView',
  emits: ['markerClicked', 'loaded'],
  props: {
    markers: {
      type: Array as PropType<Array<Marker>>,
    },
    map_data: {
      type: Object,
    },
    data_loaded: {
      type: Boolean,
      default: false,
    },
    overlap: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Spinner,
  },
  setup(props, { emit }) {
    const mapRef = ref(),
      mapInstance = ref<mapboxgl.Map>(),
      mapLoaded = ref()

    function getFeatures(): GeoJSON.Feature<GeoJSON.Geometry>[] {
      return (props.markers ?? []).map((m, i) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [m.lng, m.lat],
        },
        properties: {
          index: i,
          marker: 'marker',
          priority: 2,
          iconSize: 0.75,
        },
      }))
    }

    function setMarkers() {
      if (!mapInstance.value || !props.markers) return

      const source = mapInstance.value.getSource('markers') as GeoJSONSource
      if (source) {
        source.setData({
          type: 'FeatureCollection',
          features: getFeatures(),
        });
        return;
      }

      mapInstance.value.addSource(`markers`, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: getFeatures(),
        },
      })

      mapInstance.value.addLayer({
        id: `markers`,
        type: 'symbol',
        source: `markers`,
        minzoom: 12,
        layout: {
          'text-allow-overlap': props.overlap,
          'text-ignore-placement': props.overlap,
          'icon-allow-overlap': props.overlap,
          'icon-ignore-placement': props.overlap,
          'icon-image': ['get', 'marker'],
          'icon-anchor': 'bottom',
          'icon-size': ['get', 'iconSize'],
          'text-field': ['get', 'title'],
          'symbol-sort-key': ['to-number', ['get', 'priority']],
        },
      })
    }

    async function loadImage(src: any): Promise<HTMLImageElement> {
      return await new Promise((res, err) => {
        mapInstance.value?.loadImage(src, function (error: any, image: any) {
          if (error) return err(error)
          res(image)
        })
      })
    }

    function setVisible() {
      if (!mapInstance.value) return

      if (!mapLoaded.value) {
        emit('loaded')
      }

      mapInstance.value.resize()
      mapInstance.value.triggerRepaint()
      mapLoaded.value = true
    }

    function renderMapWhenReady() {
      const interval = setInterval(() => {
        if (!mapInstance.value || !mapRef.value) {
          clearInterval(interval)
          return
        }

        if (mapRef.value.getBoundingClientRect().height > 0 && props.data_loaded) {
          setMarkers()
          setTimeout(setVisible, 500)
          clearInterval(interval)
        }
      }, 50)
    }

    watchEffect(() => {
      if (props.markers && mapLoaded.value) {
        setMarkers()
        setTimeout(setVisible, 500)
      }
    })

    onMounted(() => {
      mapInstance.value = new mapboxgl.Map({
        container: mapRef.value, // container ID
        style: 'mapbox://styles/mapbox/streets-v11', // style URL
        center: [5.580534, 50.632403], // starting position [lng, lat]
        zoom: 13, // starting zoom
        ...props.map_data,
      })

      mapInstance.value.on('load', async () => {
        if (!mapInstance.value) return

        try {
          const custom_marker = await loadImage(marker)
          mapInstance.value.addImage('marker', custom_marker)
        } catch (e) {}

        mapInstance.value.on('click', 'markers', function (e: any) {
          emit('markerClicked', (props?.markers ?? [])[e.features[0]?.properties.index])
        })

        renderMapWhenReady()
      })
    })

    return { mapLoaded, mapRef, mapInstance }
  },
})
</script>

<style lang="scss" scoped>
.mapview {
  min-height: 100% !important;
  overflow: hidden;

  opacity: 0;
  transition: opacity 0.25s ease-in-out;

  &.loaded {
    opacity: 1;
  }
}
</style>
