<template>
  <div v-if="component" class="d-flex flex-column flex-grow-1 position-relative">
    <PageHeader>
      <template #default>
        <div class="d-flex gap-2">
          <router-link class="btn btn-sm d-inline-flex m-0 p-0" :to="{ name: 'view-components' }">
            <span class="material-icons me-2">undo</span>
          </router-link>
          Page
        </div>
      </template>
      <template #right>
        <text-input
          class="border"
          inputClass="fs-4"
          style="flex: 1 0"
          :value="newData.key"
          @change="update({ key: $event })"
        />
      </template>
      <template #message>{{ component?.component_templates_id.key }}</template>
    </PageHeader>
    <transition appear name="fade" mode="out-in">
      <div
        class="content d-flex flex-column flex-xl-row-reverse flex-grow-1 position-relative gap-3 align-items-xl-start"
      >
        <div style="min-width: 290px; top: 1rem" class="sticky-xl-top">
          <div class="d-flex flex-column p-3 border rounded-3 bg-white mb-2">
            <div class="d-flex justify-content-between align-items-center gap-5" data-v-19a25a2a="">
              <span class="fs-7 fw-bolder text-muted" data-v-19a25a2a="">Status</span>
              <select class="form-select" :value="newData.status" @input="update({ status: $event.target.value })">
                <option value="published">Published</option>
                <option value="draft">Draft</option>
                <option value="archived">Archived</option>
              </select>
            </div>
            <hr style="margin: 0.5rem 0" />
            <properties
              :object="{
                Template: component.component_templates_id.key,
                'Date Created': new Date(component.date_created).toLocaleString(),
                'Last Updated': new Date(component.date_updated).toLocaleString(),
              }"
            />
          </div>
          <div class="d-flex justify-content-end gap-2">
            <router-link
              class="btn btn-success btn-sm d-flex"
              :to="{ name: 'view-components', params: { save: true } }"
            >
              <span class="material-icons me-2">save</span>
              <span class="fs-6">Save</span>
            </router-link>
          </div>
        </div>

        <metadata-edit
          :media="component.media ?? []"
          :template="component.component_templates_id.metadata"
          :values="component.metadata"
          @update="updateField"
          @media="updateMedia"
          @medias="updateMedias"
          @localisedMedia="updateLocalisedMedia"
        />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import axios from '@/api'
import { isEqual } from 'lodash'

import { PageHeader } from '@avriopolis/common/components'
import LocalisedValue from '@/components/misc/LocalisedValue.vue'
import MetadataEdit from '@/components/metadata/MetadataEdit.vue'
import Properties from '@/components/misc/Properties.vue'
import MediaViewer from '@/components/media/MediaViewer.vue'
import TextInput from '@/components/inputs/TextInput.vue'
import { pushChange } from '@/store/changes'
import project from '@/store/project'

export default defineComponent({
  name: 'View_Component',
  emits: ['loading'],
  components: {
    PageHeader,
    LocalisedValue,
    MetadataEdit,
    Properties,
    MediaViewer,
    TextInput,
  },
  setup(props, { emit }) {
    const route = useRoute()
    const component = ref<Component>()
    const metadata = ref()
    const newData = reactive({
      key: component.value?.key,
      status: component.value?.status,
    })

    const fetchData = async () => {
      const response = await axios.get(`/items/components/${route.params.id}?fields=*.*`)
      component.value = response?.data?.data
      metadata.value = component.value?.metadata
      newData.key = component.value?.key
      newData.status = component.value?.status
    }

    async function updateData() {
      emit('loading', true)
      try {
        await fetchData()
      } catch (e) {}
      emit('loading', false)
    }

    async function updateField({ id, value }: { id: string; value: any }) {
      if (component.value) {
        const existing = metadata.value[id]

        if (!isEqual(existing, value)) {
          metadata.value = { ...metadata.value, [id]: value }
          pushChange('ComponentMeta', component.value?.id, metadata.value)
        } else {
          pushChange('ComponentMeta', component.value?.id, { [id]: undefined })
        }
      }
    }

    async function updateMedia({ tag, directus_files_id }: { tag: string; directus_files_id: string }) {
      const existing = component.value?.media?.find((m) => m.tags?.includes(tag))
      if (existing) {
        pushChange('ComponentMedia', existing.id, { directus_files_id })
      } else {
        pushChange('ComponentMedia', 0, {
          status: 'published',
          components_id: component.value?.id,
          projects_id: project.value?.active?.id,
          directus_files_id,
          types: [],
          tags: [tag],
          locked: false,
        })
      }
      console.log('Updating Media', tag, directus_files_id, 'from:', existing)
    }

    async function updateMedias({ tag, directus_files_id }: { tag: string; directus_files_id: string[] }) {
      console.log('Updating Medias', tag, directus_files_id)

      //for (let file_id of directus_files_id) {
      pushChange(
        'ComponentMedia',
        0,
        directus_files_id.map((file_id) => ({
          status: 'published',
          components_id: component.value?.id,
          projects_id: project.value?.active?.id,
          directus_files_id: file_id,
          types: [],
          tags: [tag],
          locked: false,
        })),
      )
      //}
    }

    async function updateLocalisedMedia({ tag, directus_files_id }: { tag: string; directus_files_id: string }) {
      if (!tag) return
      console.log('Updating Localised Media:', tag, directus_files_id)
      if (!directus_files_id) {
        pushChange('ComponentLocalisedMedia', tag, {
          status: undefined,
          components_id: undefined,
          projects_id: undefined,
          directus_files_id: undefined,
          types: undefined,
          tags: undefined,
          locked: undefined,
        })
      } else {
        pushChange('ComponentLocalisedMedia', tag, {
          status: 'published',
          components_id: component.value?.id,
          projects_id: project.value?.active?.id,
          directus_files_id,
          types: [],
          tags: [tag],
          locked: false,
        })
      }
    }

    async function update(data?: any) {
      if (component.value) {
        let allEqual = true
        for (let key of Object.keys(data)) {
          if (!isEqual((component.value as any)[key], data[key])) {
            allEqual = false
          }
        }

        if (!allEqual) {
          Object.assign(newData, data)
          pushChange('Component', component.value.id, data)
        } else {
          pushChange(
            'Component',
            component.value.id,
            Object.keys(data).reduce((p, c) => ({ [c]: undefined }), {}),
          )
        }
      }
    }

    updateData()

    return { component, updateField, updateMedia, updateMedias, update, newData, updateLocalisedMedia }
  },
  beforeRouteEnter(to, _from, next) {
    if (!to.params.tab) {
      return next({ ...to, params: { ...to.params, tab: 'info' } })
    }
    next()
  },
})
</script>
