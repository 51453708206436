<template>
  <div class="template-picker">
    <iframe class="w-full h-full" v-if="iframeSrc" :src="iframeSrc"></iframe>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { PageHeader } from '@avriopolis/common/components'
import { onMounted, ref, defineEmits } from 'vue';

import { v4 } from 'uuid';

export default defineComponent({
  name: 'Dashboard',
  emits: ['loading'],
  components: {
    PageHeader,
  },
  props: {
    briefId: String,
    user: {
      type: String,
      default: 'mapslive@glooh.media',
    },
    password: {
      type: String,
      default: 'demo123',
    }
  },
  setup(props, { emit }) {
    
    const iframeSrc = ref('');

    onMounted(async () => {
      emit('loading', true)

      const response = await fetch('https://ecooh.staging.glooh.app/custom/login', {
        method: 'POST', 
        "headers": {
            "Content-Type": "application/json"
        }, 
        body: JSON.stringify({ user: props.user, password: props.password }) as any
      })
      const data = await response.json();
      
      const creative_id = props.briefId;
      const initData = '';

      iframeSrc.value = `https://ecooh.staging.glooh.media?refresh_token=${data.refreshToken}&access_token=${data.accessToken}&campaign_brief_id=${props.briefId}&creative_id=${creative_id}&initData=${initData}&tag=mapslive_demo`;
        emit('loading', false)
    })

    return { iframeSrc }
  }
})
</script>


<style>
.template-picker {
  width: 100%;
  height: 100%;
  flex: 1;
}
iframe {
  width: 100%;
  height: 100%;
}

.my-campaign.ecooh {
  padding: 2rem;
}
</style>