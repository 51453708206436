<template>
  <div v-if="organised_groups.length > 0" class="d-flex flex-column flex-grow-1 gap-2 mb-5 border rounded-3 bg-white">
    <div v-for="(group, i) in organised_groups" :key="i" class="group d-flex flex-column px-3">
      <h4 class="border-bottom py-2">{{ group.name }}</h4>
      <div class="field" v-for="(field, i) in group.fields" :key="i">
        <div class="d-flex align-items-end justify-content-between">
          <h6 class="mb-2">{{ field.fields_id.name }}</h6>
          <div v-if="field.locked" class="p-1 text-danger" style="opacity: 0.25" @click="toggleLock(field)">
            <span class="material-icons">{{ field.locked ? 'lock' : 'lock_open' }}</span>
          </div>
        </div>

        <template v-if="field.fields_id.type === 'destination'">
          <Destination indent view class="mb-2" :value="field.value ?? undefined" />
        </template>
        <template v-else-if="field.fields_id.type === 'custom_icon'">
          <div class="map_wrap" v-if="field.value.icon && field.value.size && field.value.position.coordinates">
            <inputPosition
              :image="field.value.icon"
              :size="field.value.size"
              :position="field.value.position.coordinates"
              :zoom="14"
              :show="true"
            />
          </div>
        </template>
        <template v-else>
          <localised-value :value="field.value" v-slot="{ value }">
            <p class="bg-light border p-2" v-html="value"></p>
          </localised-value>
        </template>
        <hr v-if="i < group.fields.length - 1" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import WYSIWYG from '@/components/inputs/WYSIWYG.vue'
import TextInput from '@/components/inputs/TextInput.vue'
import LocalisedValue from '@/components/misc/LocalisedValue.vue'
import Destination from '@/components/inputs/Destination.vue'
import inputPosition from '@avriopolis/common/components/inputPosition.vue'

export default defineComponent({
  name: 'Fields',
  props: {
    fields: {
      type: Array as PropType<Array<FieldValue>>,
      required: true,
    },
  },
  components: {
    WYSIWYG,
    TextInput,
    LocalisedValue,
    Destination,
    inputPosition,
  },
  setup(props) {
    const organised_groups = computed(() => {
      const groups: { name: String; fields: FieldValue[] }[] = []
      for (let field of props.fields ?? []) {
        if (field.value == undefined) continue
        let group = groups.find((g) => g.name === field.fields_id.group)
        if (!group) {
          group = { name: field.fields_id.group, fields: [] }
          groups.push(group)
        }
        group.fields.push(field)
      }
      return groups
    })

    return { organised_groups }
  },
})
</script>
<style scoped>
.map_wrap >>> details {
  display: none;
}
</style>
