<template>
  <nav
    v-if="page > 0 && items > perPage"
    class="pagination-wrapper d-flex flex-column align-items-center justify-content-center my-3"
  >
    <ul class="pagination pagination-sm mb-0">
      <li class="page-item" :class="{ disabled: page <= 1 }" v-if="pages >= 4">
        <button class="page-link" @click="$emit('page', 1)">
          <span class="material-icons">first_page</span>
        </button>
      </li>
      <li class="page-item me-2" :class="{ disabled: page <= 1 }" v-if="pages >= 3">
        <button class="page-link" @click="$emit('page', page - 1)">
          <span class="material-icons">navigate_before</span>
        </button>
      </li>

      <li class="page-item me-2" v-if="pages > 10 && roundedDown !== -1">
        <button class="page-link" @click="$emit('page', roundedDown)">
          {{ roundedDown }}
        </button>
      </li>

      <li class="page-item" v-if="page > 2 && page > pages - 1">
        <button class="page-link" @click="$emit('page', page - 2)">{{ page - 2 }}</button>
      </li>
      <li class="page-item" v-if="page > 1">
        <button class="page-link" @click="$emit('page', page - 1)">{{ page - 1 }}</button>
      </li>
      <li class="page-item active">
        <button class="page-link" href="#">{{ page }}</button>
      </li>
      <li class="page-item" v-if="page < pages">
        <button class="page-link" @click="$emit('page', page + 1)">{{ page + 1 }}</button>
      </li>
      <li class="page-item" v-if="page < pages - 1 && page < 2">
        <button class="page-link" @click="$emit('page', page + 2)">{{ page + 2 }}</button>
      </li>

      <li class="page-item ms-2" v-if="pages > 20 && roundedUp !== -1">
        <button class="page-link" @click="$emit('page', roundedUp)">
          {{ roundedUp }}
        </button>
      </li>

      <li class="page-item ms-2" :class="{ disabled: page >= pages }" v-if="pages >= 3">
        <button class="page-link" @click="$emit('page', page + 1)">
          <span class="material-icons md-24">navigate_next</span>
        </button>
      </li>
      <li class="page-item" :class="{ disabled: page >= pages }" v-if="pages >= 4">
        <button class="page-link" @click="$emit('page', pages)">
          <span class="material-icons">last_page</span>
        </button>
      </li>
    </ul>
    <small class="mt-1">
      Showing {{ (page - 1) * perPage + 1 }} - {{ Math.min(items, page * perPage) }} of {{ items }} items
    </small>
  </nav>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'Pagination',
  emits: ['page'],
  props: {
    page: {
      type: Number,
      default: 1,
    },
    items: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 10,
    },
  },
  setup(props) {
    const pages = computed(() => Math.ceil(props.items / props.perPage))
    const roundedDown = computed(() => {
      const prev10 = props.page % 10 === 0 ? props.page - 10 : props.page - ((props.page + 10) % 10)
      return props.page <= 11 ? -1 : prev10
    })
    const roundedUp = computed(() => {
      const next10 = props.page + (10 - ((props.page + 10) % 10))
      return pages.value < next10 || Math.abs(next10 - props.page) <= 1 ? -1 : next10
    })
    return { pages, roundedDown, roundedUp }
  },
})
</script>

<style lang="scss" scoped>
.pagination-wrapper {
  user-select: none;
  .page-link {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;

    height: 32px;
    width: 32px;
  }
}
</style>
