<template>
  <router-view v-slot="{ Component }">
    <transition appear name="fade" mode="out-in">
      <component :key="$route.matched[0]?.path" :is="Component" />
    </transition>
  </router-view>

  <div class="position-fixed bottom-0 end-0 p-3 toast-container" style="z-index: 5">
    <toast
      :message="$t('toast.welcome', { user: user?.full_name })"
      :timestamp="Date.now()"
      :show="showWelcome"
      @close="showWelcome = false"
    />
    <toast class="bg-warning" :message="$t('toast.autologout')" :delay="undefined" :show="displayWarning" />
  </div>

  <div id="modals" />
</template>

<script lang="ts" scoped>
import { defineComponent, computed, ref } from 'vue'

import { Toast } from '@avriopolis/common/components'
import Auth from '@/store/auth'
import activity from '@/store/activity'

export default defineComponent({
  name: 'App',
  components: {
    Toast,
  },
  setup() {
    const showWelcome = ref(!!Auth.user.value && !!Auth.token.value)
    const displayWarning = computed(() => activity.displayWarning)
    return { displayWarning, user: Auth.user, showWelcome }
  },
})
</script>

<style lang="scss">
#modals {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5000;
  pointer-events: none;
}

.modal-dialog {
  display: flex;
  max-height: 90%;
  overflow: hidden;

  &.center {
    position: absolute;
    left: 0;
    right: 0;
    top: 50vh;
    transform: translate(0, -50%) !important;
    margin: auto;
  }

  .modal-body {
    flex-grow: 1;
    overflow: auto;
  }
}

.swal2-container {
  z-index: 5200;
}

.offcanvas-end {
  top: 4rem;
}

.nav-link:not(.router-link-active):hover {
  border-color: #525252 !important;
  &.text-danger {
    border-color: #dc3545 !important;
  }
}
</style>
