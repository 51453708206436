export default {
  header: {
    search: {
      placeholder: 'Rechercher...',
    },
    menu: {
      logout: 'Se déconnecter',
    },
  },

  sidebar: {
    menu: {
      dashboard: 'Tableau de bord',
      poi: "Points d'intérêt",
      announcements: 'Annonces',
      media: 'Médias',
      navigation: 'Menus',
      pages: 'Pages',
      categories: 'Catégories',
      footer: {
        switch: 'Changer de projet',
        help: 'Support',
        logout: 'Se déconnecter',
      },
    },
  },

  toast: {
    welcome: 'Bienvenue à nouveau, {user}',
    autologout: `Vous êtes sur le point d'être déconnecté pour inactivité.`,
  },

  customIcon: {
    preview: 'Votre icône',
    choseIcon: 'Choisissez une icône',
    size: 'Taille de votre icône',
    dragMe: 'Déplace moi',
    dragMeDesc: 'Déplace moi pour définir ma position',
    noIcon: "Pas d'icône",
    noIconDesc: 'Vous devez définir une icône personnalisée.',
  },

  categories: {
    edit: {
      title: 'Editer catégorie',
      status: {
        title: 'Statut',
        draft: 'Brouillon',
        published: 'Publié',
        archived: 'Archivé',
      },
      name: 'Nom',
      description: 'Description',
      map_display: {
        title: "Options d'affichage de la carte",
        type: {
          title: "Type d'affichage de la carte",
          label: 'Label',
          icon: 'Icone',
          icon_label: 'Icone & label',
          none: 'Ne pas afficher',
        },
        category_importance: {
          title: 'Importance de la catégorie',
          not_important: 'Peu important',
          very_important: 'Très important',
        },
        zoom_display: {
          title: 'Affichage Zoom',
          min: 'Min',
          max: 'Max',
        },
        icon: {
          title: 'Icone',
          clear: 'Effaçer',
        },
        map_marker_size: {
          title: 'Taille du marqueur',
          min: 'Min',
          max: 'Max',
        },
      },
      save: 'Sauver',
      save_changes: 'Sauver les changements',
    },
  },
  dashboard: {
    status: {
      ping_title: 'Last pings',
      screenshot_title: 'Last screenshot',
      software_title: 'Software',
      hardware_title: 'Hardware',
      button_labels: {
        restart: 'Force to restart',
        refreshing: 'Refreshing',
      },
    },
  },
}
