<template>
  <centered-form>
    <h1>Called Back!</h1>
  </centered-form>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import { CenteredForm } from '@avriopolis/common/components'
import Auth from '@/store/auth'
import { useRouter } from 'vue-router'
import { fetchProjects } from '@/store/project'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'LoginCallbackPage',
  components: {
    CenteredForm,
  },
  setup() {
    const router = useRouter()
    const i18n = useI18n()

    onMounted(async () => {
      await Auth.refresh()
      await fetchProjects()
      router.push({ name: 'select-project' })
    })

    return {}
  },
})
</script>
