<template>
  <div class="d-flex flex-column h-100 mx-auto" style="max-width: 800px">
    <div class="d-flex flex-column flex-md-row gap-md-4 px-2">
      <div class="d-flex flex-column" style="flex: 1">
        <h6 class="mb-1">Channels</h6>
        <custom-select
          class="mb-4"
          placeholder="Select Channels"
          :options="['signage', 'kiosk']"
          :selected="channels"
          @selected="toggleChannel($event)"
        />
      </div>
      <div class="d-flex flex-column" style="flex: 1">
        <h6 class="mb-1">Environments</h6>
        <custom-select
          class="mb-4"
          placeholder="Select Environments"
          :options="['staging', 'production']"
          :selected="environments"
          @selected="toggleEnvironment($event)"
        />
      </div>
    </div>
    <div class="d-flex flex-column flex-md-row gap-md-4 px-2">
      <div class="mb-3 flex-grow-1">
        <h6 class="mb-1">Available from</h6>
        <input type="datetime-local" class="form-control" id="from" :value="from" @input="dateChanged" />
      </div>
      <div class="mb-3 flex-grow-1">
        <h6 class="mb-1">Available to</h6>
        <input type="datetime-local" class="form-control" id="to" :value="to" @input="dateChanged" />
      </div>
    </div>

    <h6 class="my-2 d-flex gap-2 align-items-end justify-content-between px-2">
      Exceptions
      <button class="btn btn-primary p-1 m-0" @click="createException">
        <span class="material-icons">add</span>
      </button>
    </h6>

    <div v-if="exceptions" class="mb-3 d-flex flex-column gap-2">
      <div
        v-for="(exception, index) in exceptions"
        :key="index"
        class="d-flex flex-column flex-sm-row gap-2 align-items-sm-center justify-content-center p-2 border border-2 rounded-3 daterange"
      >
        <input
          type="datetime-local"
          class="form-control"
          :value="exception.date_from"
          @input="exceptionDateChanged(index, 'from', $event.target.value)"
        />
        <span class="material-icons to">chevron_right</span>
        <input
          type="datetime-local"
          class="form-control"
          :value="exception.date_to"
          @input="exceptionDateChanged(index, 'to', $event.target.value)"
        />
        <button class="btn btn-danger btn-sm p-1" @click="removeException(index)">
          <span class="material-icons">delete</span>
        </button>
      </div>
    </div>

    <hr />

    <div class="d-flex align-items-center justify-content-end">
      <!-- Day parting-->

      <DayParting :dayparting="availability.dayparting" @updateDayparting="updateDayparting" />
    </div>
    <div class="d-flex align-items-center justify-content-end">
      <router-link
        :to="{ params: { tab: 'info', save: true } }"
        class="btn btn-success my-4 gap-2"
        :class="{ disabled: hasChanges }"
      >
        <span class="material-icons">save</span>
        Save
      </router-link>
    </div>
    <hr class="my-4" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import { isEqual, cloneDeep } from 'lodash'

import { pushChange, hasChanges as saveEnabled } from '@/store/changes'
import CustomSelect from '@/components/inputs/CustomSelect.vue'
import DayParting from '@/components/misc/DayParting.vue'
import Swal from 'sweetalert2'

export default defineComponent({
  name: 'Availability',
  props: {
    availability: {
      type: Object as PropType<Availability>,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  components: {
    CustomSelect,
    DayParting,
  },
  setup(props, { emit }) {
    const channels = ref<string[] | null>(cloneDeep(props.availability.channels))
    const environments = ref<string[] | null>(cloneDeep(props.availability.environments))
    const exceptions = ref<Exception[] | null>(cloneDeep(props.availability.exceptions))
    const dayparting = ref<Dayparting | null>(cloneDeep(props.availability.dayparting))
    const from = ref<string | null>(props.availability?.date_from),
      to = ref<string | null>(props.availability?.date_to)

    function isArrayEqual(a: any, b: any) {
      if (Array.isArray(a) || Array.isArray(b)) {
        if (a === null && (b === null || b.length === 0)) return true
        if (b === null && (a === null || a.length === 0)) return true
      }
      return isEqual(a, b)
    }

    function updateAvailability() {
      const changes: { [key: string]: any } = {}
      changes['date_to'] = !isEqual(to.value, props.availability.date_to) ? to.value : undefined
      changes['date_from'] = !isEqual(from.value, props.availability.date_from) ? from.value : undefined
      changes['channels'] = !isArrayEqual(channels.value, props.availability.channels) ? channels.value : undefined
      changes['environments'] = !isArrayEqual(environments.value, props.availability.environments)
        ? environments.value
        : undefined
      changes['exceptions'] = !isArrayEqual(exceptions.value, props.availability.exceptions)
        ? exceptions.value
        : undefined
      changes['dayparting'] = !isEqual(dayparting.value, props.availability.dayparting) ? dayparting.value : undefined
      pushChange(props.type, props.availability?.id, changes)
    }

    function updateDayparting(data: Dayparting) {
      dayparting.value = data
      updateAvailability()
    }

    function toggleChannel(id: string) {
      if (!channels.value) channels.value = []
      const index = channels.value.indexOf(id)
      if (index !== -1) {
        channels.value.splice(index, 1)
      } else {
        channels.value.push(id)
      }
      updateAvailability()
    }

    function toggleEnvironment(id: string) {
      if (!environments.value) environments.value = []
      const index = environments.value.indexOf(id)
      if (index !== -1) {
        environments.value.splice(index, 1)
      } else {
        environments.value.push(id)
      }
      updateAvailability()
    }

    function createException() {
      if (!exceptions.value) exceptions.value = []

      exceptions.value.push({
        [`${props.type.toLowerCase()}_id`]: props.availability.id,
        date_from: null,
        date_to: null,
      } as any)

      pushChange('Exceptions', 0, exceptions.value?.filter((c) => c.id === undefined) ?? [])
    }

    function removeException(index: number) {
      const exception = exceptions.value![index]
      exceptions.value!.splice(index, 1)

      if (exception.id === undefined) {
        pushChange('Exceptions', 0, exceptions.value?.filter((c) => c.id === undefined) ?? [])
      } else {
        const originals = props.availability.exceptions ?? []
        const removed = originals.filter((c) => exceptions.value!.findIndex((c2) => c2.id === c.id) === -1)

        pushChange('Exceptions', exception.id!, { date_to: undefined, date_from: undefined })

        pushChange(
          'Exceptions',
          -1,
          removed.map((c) => c.id),
        )
      }
    }

    function exceptionDateChanged(index: number, input: string, value: any) {
      const exception = exceptions.value![index]
      const existing = props.availability.exceptions?.find((e) => e.id === exception.id)

      if (input === 'from') {
        exception.date_from = value
      } else if (input === 'to') {
        exception.date_to = value
      }

      if (!existing) {
        pushChange('Exceptions', 0, exceptions.value?.filter((c) => c.id === undefined) ?? [])
      } else {
        if (input === 'from') {
          pushChange('Exceptions', exception.id!, { date_from: value })
        } else if (input === 'to') {
          pushChange('Exceptions', exception.id!, { date_to: value })
        }
      }
    }

    function dateChanged(e: any) {
      if (e.target.id === 'from') {
        if (new Date(e.target.value) > new Date(to.value ?? '')) {
          e.target.value = from.value;
          Swal.fire({
            title: `The from date cannot be after the to date.`,
            icon: 'warning',
            confirmButtonColor: '#0d6efd',
          })
        } else {
          from.value = e.target.value
        }
      } else if (e.target.id === 'to') {
        if (new Date(e.target.value) < new Date(from.value ?? '')) {
          e.target.value = to.value;
          Swal.fire({
            title: `The to date cannot be before the from date.`,
            icon: 'warning',
            confirmButtonColor: '#0d6efd',
          })
        } else {
          to.value = e.target.value
        }
      } else {
        return
      }
      updateAvailability()
    }

    const hasChanges = computed(() => !saveEnabled())

    return {
      channels,
      environments,
      toggleChannel,
      toggleEnvironment,
      hasChanges,
      from,
      to,
      dateChanged,
      exceptionDateChanged,
      exceptions,
      createException,
      removeException,
      updateDayparting,
    }
  },
})
</script>

<style lang="scss" scoped>
@media screen and (max-width: 767px) {
  .daterange {
    .to {
      transform: rotate(90deg);
      margin: auto;
      height: 24px;
      width: 24px;
    }
  }
}
</style>
