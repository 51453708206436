<template>
  <div>
    <teleport to="#modals">
      <div class="modal fade" id="cropImage" tabindex="-1" ref="modalRef">
        <div class="modal-dialog crop-media center">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Crop Image</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="$emit('cancel')"
              ></button>
            </div>
            <div class="modal-body d-flex flex-column gap-2">
              <div class="img-wrapper">
                <img id="image" :src="`${mediaBase}/assets/${file.id}`" ref="imageToCrop" />
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="$emit('cancel')">
                Cancel
              </button>
              <button type="button" class="btn btn-primary" @click="applyCrop">Submit Cropped Image</button>
            </div>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, ref } from 'vue'
import axios from '@/api'

import { Modal } from 'bootstrap'
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.min.css'
import { dataURLtoFile } from '@/utils/misc'
import Swal from 'sweetalert2'

export default defineComponent({
  name: 'MediaCropper',
  emits: ['cancel', 'cropped'],
  props: {
    file: {
      type: Object as PropType<DirectusFile>,
      required: true,
    },
    options: {
      type: Object as PropType<any>,
      default: {},
    },
  },
  setup(props, { emit }) {
    let modalRef = ref()
    let modalInstance = ref()
    let imageToCrop = ref()
    let cropper = ref()
    let cropping = ref(false)

    async function applyCrop() {
      cropping.value = true
      const croppedImage = cropper.value.getCroppedCanvas().toDataURL(props.file.type)

      const formData = new FormData()
      formData.append('title', props.file.title)
      formData.append('filename_download', props.file.filename_download)
      formData.append('type', props.file.type)
      formData.append('file', dataURLtoFile(croppedImage, props.file.filename_download))

      const onerror = () =>
        Swal.fire({
          title: `There was an error uploading the cropped image, Please try again later.`,
          icon: 'warning',
          confirmButtonColor: '#0d6efd',
        })

      try {
        const { status, data } = await axios.post('/files', formData)
        if (status === 200 && data?.data?.id) {
          if (modalInstance.value && modalInstance.value.dispose) {
            modalInstance.value.hide()
          }
          emit('cropped', data.data)
        } else {
          onerror()
        }
      } catch (e) {
        onerror()
      }
    }

    onMounted(() => {
      modalRef.value.addEventListener('shown.bs.modal', () => {
        if (cropper.value && cropper.value.destroy) {
          cropper.value.destroy()
        }
        cropper.value = new Cropper(imageToCrop.value, {
          viewMode: 3,
          dragMode: 'move',
          toggleDragModeOnDblclick: false,
          ...props.options,
        })
      })

      if (modalInstance.value && modalInstance.value.dispose) {
        modalInstance.value.dispose()
      }

      modalInstance.value = new Modal(modalRef.value, { backdrop: 'static', keyboard: false })
      modalInstance.value.show()
    })

    return { modalRef, imageToCrop, applyCrop, mediaBase: import.meta.env.VITE_API_URL }
  },
})
</script>

<style lang="scss" scoped>
.img-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;

  img {
    max-width: 100%;
  }
}
</style>
