<template>
  <div v-if="points_of_interest" class="d-flex flex-column flex-grow-1 position-relative">
    <PageHeader>
      <template #default><localised-value :value="points_of_interest?.title" /></template>
      <template #message>Point of Interest</template>
      <template #right>
        <ul class="nav nav-pills justify-content-center">
          <li class="nav-item">
            <a class="nav-link disabled">Display</a>
          </li>
          <div class="d-flex flex-row">
            <li class="nav-item">
              <router-link class="nav-link d-flex" active-class="active" :to="{ params: { tab: 'info' } }">
                Info
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link d-flex" active-class="active" :to="{ params: { tab: 'media' } }">
                Media
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link d-flex" active-class="active" :to="{ params: { tab: 'availability' } }">
                Availability
              </router-link>
            </li>
            <!--<li class="nav-item">
              <router-link
                class="nav-link d-flex disabled"
                active-class="active"
                :to="{ params: { tab: 'availability' } }"
              >
                Availability
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link d-flex disabled"
                active-class="active"
                :to="{ params: { tab: 'targeting' } }"
              >
                Targeting
              </router-link>
            </li>-->
          </div>
        </ul>
      </template>
    </PageHeader>
    <transition appear name="fade" mode="out-in">
      <div
        v-if="$route.params.tab === 'info'"
        class="content d-flex flex-column flex-xl-row-reverse flex-grow-1 position-relative gap-3 align-items-xl-start"
      >
        <div style="min-width: 290px; top: 1rem" class="sticky-xl-top">
          <div class="d-flex flex-column p-3 border rounded-3 bg-white mb-2">
            <properties
              :object="{
                Name: points_of_interest?.title,
                Status: points_of_interest?.status,
                'POI Status': points_of_interest?.poi_status,
                Source: points_of_interest?.source,
                'Date Created': new Date(points_of_interest?.date_created).toLocaleString(),
                'Last Updated': new Date(points_of_interest?.date_updated).toLocaleString(),
                Categories: points_of_interest?.categories?.map((c) => c.categories_id.name),
                Tags: points_of_interest?.tags,
              }"
            />
          </div>
          <div class="d-flex justify-content-end gap-2">
            <router-link class="btn btn-secondary btn-sm d-flex" :to="{ name: 'edit-points_of_interest' }">
              <span class="material-icons me-2">edit</span>
              <span class="fs-6">Edit</span>
            </router-link>
            <button class="btn btn-danger btn-sm d-flex" @click="archive(points_of_interest?.id)">
              <span class="material-icons me-2">archive</span>
              <span class="fs-6">Archive</span>
            </button>
          </div>
        </div>

        <fields-view :fields="points_of_interest?.fields" />
      </div>
      <div class="content" v-else-if="$route.params.tab === 'media'">
        <media-viewer
          :query="{ filter: { points_of_interest_id: points_of_interest?.id } }"
          @loading="$emit('loading', $event)"
        />
      </div>
      <div class="content" v-else-if="$route.params.tab === 'announcements'">
        <h1>announcements</h1>
      </div>
      <div class="content flex-grow-1" v-else-if="$route.params.tab === 'availability'">
        <availability
          type="Points_of_Interest"
          :availability="points_of_interest"
          @loading="$emit('loading', $event)"
        />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import axios from '@/api'
import Swal from 'sweetalert2'

import { PageHeader } from '@avriopolis/common/components'
import LocalisedValue from '@/components/misc/LocalisedValue.vue'
import FieldsView from '@/components/fields/FieldsView.vue'
import Properties from '@/components/misc/Properties.vue'
import MediaViewer from '@/components/media/MediaViewer.vue'
import Availability from '@/components/misc/Availability.vue'

export default defineComponent({
  name: 'View_Points_of_Interest',
  emits: ['loading'],
  components: {
    PageHeader,
    LocalisedValue,
    FieldsView,
    Properties,
    MediaViewer,
    Availability,
  },
  setup(props, { emit }) {
    const route = useRoute()
    const router = useRouter()
    const points_of_interest = ref<PointOfInterest>()

    const fetchData = async () => {
      const poiData = await axios.get(
        `/items/points_of_interest/${route.params.id}?fields=*,fields.*,fields.fields_id.*,categories.categories_id.*,exceptions.*`,
      )
      return poiData?.data?.data
    }

    async function updateData(loading: boolean = true) {
      loading && emit('loading', true)
      let poi = points_of_interest.value
      try {
        poi = await fetchData()
      } catch (e) {}
      loading && emit('loading', false)
      points_of_interest.value = poi
    }

    function archive(id: number) {
      Swal.fire({
        title: `Are you sure you want to archive this?`,
        text: `This action can only be undone by an administrator.`,
        icon: 'warning',
        confirmButtonText: 'Yes, Archive it',
        confirmButtonColor: '#dc3545',
        denyButtonColor: '#0d6efd',
        showDenyButton: true,
      }).then(async (result: any) => {
        if (result.isConfirmed) {
          await axios.patch(`items/points_of_interest/${id}`, {
            status: 'archived',
          })
          router.push({ name: 'dashboard' })
        }
      })
    }

    updateData()

    return { points_of_interest, updateData, archive }
  },
  beforeRouteUpdate(to, from) {
    if (to.params.tab && from.params.tab) {
      if (to.params.tab === 'info' && from.params.tab !== 'info') this.updateData(false)
    }
  },
  beforeRouteEnter(to, _from, next) {
    if (!to.params.tab) {
      return next({ ...to, params: { ...to.params, tab: 'info' } })
    }
    next()
  },
})
</script>
