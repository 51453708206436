<template>
  <div>
    <div class="d-flex flex-row justify-content-between align-items-center">
      <div>{{ labels.min }}</div>
      <div>{{ labels.max }}</div>
    </div>
    <Slider
      lazy
      showTooltip="focus"
      :min="min"
      :max="max"
      :model-value="values"
      :step="step"
      :direction="direction"
      :format="formatValue"
      class="slider"
      @change="onChange"
    />
  </div>
</template>

<script lang="ts">
//:format="format"

import { defineComponent, PropType, onMounted, computed } from 'vue'
import Slider from '@vueform/slider'

type Values = number[] | number

interface Labels {
  min?: string | number
  max?: string | number
}

interface Style {
  //valuesDisplay?: 'onSlider' | 'onLegend' | 'hide'
  color?: string
}

export default defineComponent({
  name: 'rangeSlider',
  emits: ['updateValue'],

  props: {
    min: {
      type: [String, Number],
      required: false,
    },
    max: {
      type: [String, Number],
      required: false,
    },
    values: {
      type: [Array, Number] as PropType<Values>,
      required: false,
      default: 0,
    },
    labels: {
      type: Object as PropType<Labels>,
      required: false,
      default: {
        min: 'min',
        max: 'max',
      },
    },
    step: {
      type: Number,
      required: false,
    },
    style: {
      type: Object as PropType<Style>,
      required: false,
    },
    direction: {
      type: String,
      required: false,
      default: 'ltr',
    },
    format: {
      type: Function,
      required: false,
    },
  },
  components: {
    Slider,
  },
  setup(props, { emit }) {
    const color = computed(() => props.style?.color ?? '#017bfe')
    function onChange(value: number) {
      emit('updateValue', value)
    }
    function formatValue(value?: number) {
      if (props.format && value) {
        return props.format(value)
      } else {
        return value
      }
    }
    onMounted(() => {})
    return {
      formatValue,
      onChange,
      color,
    }
  },
})
</script>

<style scoped>
.slider {
  --slider-connect-bg: v-bind(color);
  --slider-tooltip-bg: v-bind(color);
  --slider-handle-ring-color: v-bind(color);
}
</style>
