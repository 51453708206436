<template>
  <animation enter="fadeIn" leave="fadeOut">
    <div v-if="!loaded" class="w-100 h-100 d-flex justify-content-center align-items-center">
      <Spinner />
    </div>
    <slot v-else />
  </animation>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import Spinner from './Spinner.vue'
import Animation from './Animation.vue'

export default defineComponent({
  name: 'Field',
  props: {
    loaded: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Spinner,
    Animation,
  },
})
</script>
