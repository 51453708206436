<template>
  <centered-form>
    <h6 class="text-center mb-2">
      Welcome Back, {{ user?.full_name }}
      <br />
      <small>Please select the project you want to access.</small>
    </h6>
    <form class="mt-4">
      <div v-for="project of projects" :key="project.id" class="form-check my-2">
        <input
          class="form-check-input"
          type="radio"
          name="project"
          :id="`project-${project.id}`"
          @input="selectProject(project.id)"
        />
        <label class="form-check-label" :for="`project-${project.id}`">
          {{ project.name }}
        </label>
      </div>
      <div class="my-4">
        <div class="d-grid gap-2">
          <button class="btn btn-primary" :disabled="projectId === -1" type="button" @click="setProject">
            Continue
          </button>
          <button class="btn btn-outline-danger" type="button" @click="signOut">Sign Out</button>
        </div>
      </div>
    </form>
  </centered-form>
</template>

<script lang="ts" scoped>
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'

import projectState, { getProjectId, setProjectId } from '@/store/project'
import { CenteredForm } from '@avriopolis/common/components'

import Auth from '@/store/auth'

export default defineComponent({
  name: 'SelectProject',
  components: {
    CenteredForm,
  },
  setup() {
    const router = useRouter()
    const projectId = ref(getProjectId())

    const selectProject = (id: number) => {
      projectId.value = id
    }

    const setProject = () => {
      setProjectId(projectId.value)
      router.push({ name: 'dashboard' })
    }

    return {
      projects: projectState.value.projects,
      selectProject,
      setProject,
      projectId,
      signOut: Auth.logout,
      user: Auth.user,
    }
  },
})
</script>
