<template>
  <div class="w-100 h-100">
    <div ref="map" class="map w-100"></div>
  </div>
</template>
<script lang="ts">
import maplibregl from 'maplibre-gl'
import { defineComponent, ref, onMounted, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'map-libre',
  emits: ['update'],
  props: {
    image: {
      type: String,
    },
    size: {
      type: Number,
    },
    zoom: {
      type: Number,
    },
    position: {
      type: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    maplibregl,
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const map = ref<HTMLElement | null>(null)
    const mapInstance = ref<maplibregl.Map>()
    const mediaBase = import.meta.env.VITE_API_URL
    const features = ref<any>({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: props.position,
      },
      properties: {
        marker: 'marker',
        priority: 2,
        iconSize: props.size ? props.size : 0.5,
      },
    })
    const popup = ref<maplibregl.Popup>()

    const tooltipTitle = ref()
    const tooltipDescription = ref()

    function onMove(e: any) {
      if (!mapInstance.value) return
      var coords = e.lngLat

      features.value.geometry.coordinates = [coords.lng, coords.lat]
      mapInstance.value?.getSource('point')?.setData(features.value)
    }

    function onUp(e: any) {
      // Unbind mouse/touch events
      mapInstance.value?.off('mousemove', onMove)
      mapInstance.value?.off('touchmove', onMove)
    }

    function onDragend(e: any) {
      if (!mapInstance.value) return

      mapInstance.value.off('mousemove', onMove)
      mapInstance.value.off('touchmove', onMove)
      emit('update', e.lngLat)
      mapInstance.value.setCenter(e.lngLat)
    }

    async function loadImage(src: any): Promise<HTMLImageElement> {
      return await new Promise((res, err) => {
        mapInstance.value?.loadImage(src, function (error: any, image: any) {
          if (error) return err(error)
          res(image)
        })
      })
    }

    async function addImage(image: string | undefined) {
      if (!mapInstance.value) return

      if (image) {
        try {
          const custom_marker = await loadImage(`${mediaBase}/assets/${image}?key=marker-custom`)
          mapInstance.value.addImage('marker', custom_marker)
        } catch (e) {
          throw e
        }
      }
    }

    function removeImage() {
      if (!mapInstance.value) return
      mapInstance.value.hasImage('marker') ? mapInstance.value.removeImage('marker') : ''
    }

    watch(
      () => props.size,
      (newValue: any, oldValue: any) => {
        features.value.properties.iconSize = newValue
        mapInstance.value?.getSource('point')?.setData(features.value)
      },
    )

    watch(
      () => props.image,
      (newValue: any, oldValue: any) => {
        removeImage()
        popup.value?.remove()
        tooltipTitle.value = t('customIcon.dragMe')
        tooltipDescription.value = t('customIcon.dragMeDesc')

        if (mapInstance.value) {
          popup.value = new maplibregl.Popup({ closeOnClick: true, closeButton: false })
            .setLngLat(props.position)
            .setHTML(`<b>${tooltipTitle.value}</b><br>${tooltipDescription.value}`)
            .addTo(mapInstance.value)
        }

        addImage(newValue)
      },
    )

    onMounted(async () => {
      mapInstance.value = new maplibregl.Map({
        container: map.value!,
        style: 'https://api.maptiler.com/maps/streets/style.json?key=get_your_own_OpIi9ZULNHzrESv6T2vL',
        center: props.position,
        zoom: props.position[0] != 0 && props.position[1] != 0 ? props.zoom : 0,
      })

      mapInstance.value.on('load', function () {
        // Add a single point to the map
        mapInstance.value?.addSource('point', {
          type: 'geojson',
          data: features.value,
        })

        mapInstance.value?.addLayer({
          id: 'point',
          type: 'symbol',
          source: 'point',
          layout: {
            'icon-image': ['get', 'marker'],
            'icon-size': ['get', 'iconSize'],
          },
        })

        if (!props.show) {
          mapInstance.value?.on('mousedown', 'point', function (e) {
            // Prevent the default map drag behavior.
            e.preventDefault()
            popup.value?.remove()

            //canvas.style.cursor = 'grab'
            mapInstance.value?.on('mousemove', onMove)
            mapInstance.value?.once('mouseup', onDragend)
          })

          mapInstance.value?.on('touchstart', 'point', function (e) {
            if (e.points.length !== 1) return

            // Prevent the default map drag behavior.
            e.preventDefault()

            mapInstance.value?.on('touchmove', onMove)
            mapInstance.value?.once('touchend', onUp)
          })
        }
      })

      if (!props.image) {
        tooltipTitle.value = t('customIcon.noIcon')
        tooltipDescription.value = t('customIcon.noIconDesc')
      } else {
        tooltipTitle.value = t('customIcon.dragMe')
        tooltipDescription.value = t('customIcon.dragMeDesc')
      }
      try {
        await addImage(props.image)
      } catch (e) {
        console.error(e)
      }
      mapInstance.value.addControl(new maplibregl.NavigationControl({}))

      popup.value = new maplibregl.Popup({ closeOnClick: true, closeButton: false })
        .setLngLat(props.position)
        .setHTML(`<b>${tooltipTitle.value}</b><br>${tooltipDescription.value}`)

      if (!props.show) {
        popup.value.addTo(mapInstance.value)
      }
    })
    return {
      map,
    }
  },
})
</script>
<style scoped>
.map {
  height: 400px;
}
</style>
<style>
.mapboxgl-popup {
  margin-top: -20px;
  border-radius: 10%;
}
</style>
