<template>
  <div class="d-flex flex-column flex-grow-1 h-100">
    <PageHeader>
      <template #default>Categories</template>
      <template #right>
        <button class="btn btn-primary d-flex btn-rounded text-nowrap" @click="createCategory()">
          <span class="material-icons me-2">add</span>
          Create Category
        </button>
      </template>
    </PageHeader>
    <div class="content d-flex flex-column overflow-auto flex-grow-1 p-3">
      <div class="ms-2 form-check">
        <input
          class="form-check-input"
          type="checkbox"
          value=""
          id="showAttached"
          :checked="showAttached"
          @change="showAttached = $event.target.checked"
        />
        <label class="form-check-label" for="showAttached">Show categories attached to a POI</label>
      </div>
      <CategoryList v-if="filtered" :categories="filtered" edit small @create="createCategory" @update="updateData" />
    </div>
  </div>
</template>

<script lang="ts">
import { PageHeader } from '@avriopolis/common/components'
import Nested from '@/components/misc/Nested.vue'
import CategoryList from '@/components/misc/CategoryList.vue'
import { fetchCategories, fetch } from '@/store/cms'
import { computed, defineComponent, PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import project, { getProjectId } from '@/store/project'
import Swal from 'sweetalert2'
import axios from '@/api'
import { slugify } from '@/utils/misc'

export default defineComponent({
  name: 'Categories',
  emits: ['loading'],
  props: {
    project: {
      type: Number as PropType<number | null>,
      default: null,
    },
  },
  components: {
    Nested,
    PageHeader,
    CategoryList,
  },
  setup(props, { emit }) {
    const categories = ref<Category[]>()
    const poi_categories = ref<POICategory[]>()
    const showAttached = ref(false)

    const i18n = useI18n()

    async function updateData() {
      emit('loading', true)

      categories.value = await fetchCategories(props.project ?? getProjectId())
      const attached: POICategory[] = await fetch('points_of_interest_categories', { limit: -1 })
      poi_categories.value = attached.filter(
        (c) => categories.value?.findIndex((c1) => c1.id === c.categories_id) !== -1,
      )
      emit('loading', false)
    }

    const filtered = computed(() => {
      if (!showAttached.value) {
        return categories.value ?? []
      } else {
        const filtered_categories: number[] = []

        for (let pcat of poi_categories.value ?? []) {
          let { parent_id } = categories.value?.find((c) => c.id === pcat.categories_id) ?? {}
          filtered_categories.push(pcat.categories_id)

          while (parent_id) {
            const cat = categories.value?.find((c) => c.id === parent_id)
            filtered_categories.push(parent_id)
            parent_id = cat?.parent_id
          }
        }

        return [...new Set(filtered_categories)]
          .map((c) => categories.value?.find((c1) => c1.id === c))
          .filter((c) => !!c) as Category[]
      }
    })

    async function createCategory(parent_id?: number) {
      const result = await Swal.fire({
        input: 'text',
        inputLabel: 'Category Title',
        inputPlaceholder: 'Title',
        showCancelButton: true,
        confirmButtonText: 'Create',
        confirmButtonColor: '#0d6efd',
      })

      if (result.isConfirmed) {
        if (!result.value) {
          Swal.fire('Invalid', 'A title must be entered', 'error')
        } else {
          await axios.post('items/categories', {
            name: [{ lang: i18n.locale.value, value: result.value }],
            source: 'manual',
            slug: slugify(result.value),
            projects_id: project.value.active?.id,
            status: 'published',
            parent_id,
          })

          updateData()
        }
      }
    }

    updateData()

    return { filtered, showAttached, createCategory, updateData }
  },
})
</script>
