export default {
  header: {
    search: {
      placeholder: 'Rechercher...',
    },
    menu: {
      logout: 'Log out',
    },
  },

  sidebar: {
    menu: {
      dashboard: 'Dashboard',
      poi: 'Points of Interest',
      announcements: 'Announcements',
      media: 'Media Management',
      navigation: 'Navigation',
      pages: 'Pages',
      categories: 'Categories',
      footer: {
        switch: 'Switch Project',
        help: 'Get Help',
        logout: 'Log out',
      },
    },
  },

  toast: {
    welcome: 'Bienvenido de nuevo, {user}',
    autologout: 'Está a punto de ser desconectado por inactividad.',
  },

  customIcon: {
    preview: 'Your Icon',
    choseIcon: 'Chose an icon',
    size: 'Size of your icon',
    dragMe: 'Drag me',
    dragMeDesc: 'Deplace me to setup my position.',
    noIcon: 'No icon',
    noIconDesc: 'You have to upload an icon.',
  },

  categories: {
    edit: {
      title: 'Edit Category',
      status: {
        title: 'Status',
        draft: 'Draft',
        published: 'Published',
        archived: 'Archived',
      },
      name: 'Name',
      description: 'Description',
      map_display: {
        title: 'Map display options',
        type: {
          title: 'Map display type',
          label: 'Label',
          icon: 'Icon',
          icon_label: 'Icon & label',
          none: 'Do not display',
        },
        category_importance: {
          title: 'Category importance',
          not_important: 'Not important',
          very_important: 'Very important',
        },
        zoom_display: {
          title: 'Zoom display',
          min: 'Min',
          max: 'Max',
        },
        icon: {
          title: 'Icon',
          clear: 'Clear',
        },
        map_marker_size: {
          title: 'Map marker size',
          min: 'Min',
          max: 'Max',
        },
      },
      save: 'Save',
      save_changes: 'Save changes',
    },
  },
  dashboard: {
    status: {
      ping_title: 'Last pings',
      screenshot_title: 'Last screenshot',
      software_title: 'Software',
      hardware_title: 'Hardware',
      button_labels: {
        restart: 'Force to restart',
        refreshing: 'Refreshing',
      },
    },
  },
}
