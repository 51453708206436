<template>
  <div class="d-flex flex-row text-input position-relative" :class="{ 'form-floating': floating, [component]: true }">
    <template v-if="id && label && floating">
      <component
        v-bind="$attrs"
        :disabled="disabled"
        :id="id"
        :is="component"
        class="form-control"
        :class="inputClass"
        placeholder="floating"
        :value="localisedValue"
        @input="updateValue"
      />
      <label :for="id" class="form-label">{{ label }}</label>
    </template>
    <template v-else-if="id && label">
      <label :for="id" class="form-label">{{ label }}</label>
      <component
        v-bind="$attrs"
        :disabled="disabled"
        :id="id"
        :is="component"
        class="form-control"
        :class="inputClass"
        :value="localisedValue"
        @input="updateValue"
        :placeholder="placeholder"
      />
    </template>
    <template v-else>
      <component
        v-bind="$attrs"
        :disabled="disabled"
        :id="id"
        :is="component"
        class="form-control"
        :class="inputClass"
        :placeholder="placeholder"
        :value="localisedValue"
        @input="updateValue"
      />
    </template>
    <language-selector
      v-if="isLocalised"
      :disabled="disabled"
      icons
      class="language-selector"
      :class="{ label: label && !floating }"
      :locale="locale"
      @locale="updateLocale"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import LanguageSelector from '@/components/inputs/LanguageSelector.vue'
import { isValueLocalised, getLocalisedValue, updateLocalisedValue } from '@/utils/localisation'

export default defineComponent({
  inheritAttrs: false,
  name: 'TextInput',
  emits: ['change'],
  props: {
    component: {
      type: String,
      default: 'input',
    },
    inputClass: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Array, Object],
      default: undefined as unknown,
    },
    floating: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: undefined,
    },
    id: {
      type: String,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    localised: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LanguageSelector,
  },
  setup(props, { emit }) {
    const i18n = useI18n()
    const locale = ref(i18n.locale.value)

    const isLocalised = computed(() => {
      return props.localised || isValueLocalised(props.value)
    })

    const localisedValue = computed(() => {
      if (isLocalised.value) {
        const localisedValue = getLocalisedValue(props.value as Array<any>, locale.value)
        if (localisedValue) {
          locale.value = localisedValue.lang
          return localisedValue.value
        }
      } else {
        return props.value
      }
    })

    async function updateAndEmit(_locale: string, value: any) {
      const translations = props.value as Array<any>
      const updatedValue = await updateLocalisedValue(translations, _locale, value)
      if (updatedValue) {
        emit('change', updatedValue.value)
        locale.value = updatedValue.lang
      }
    }

    async function updateValue(e: any) {
      const newValue = e.target.value
      if (isLocalised.value) {
        updateAndEmit(locale.value, e.target.value)
      } else {
        emit('change', newValue)
      }
    }

    async function updateLocale(locale: string) {
      updateAndEmit(locale, getLocalisedValue(props.value as Array<any>, locale).value)
    }

    return { locale, isLocalised, localisedValue, updateValue, updateLocale }
  },
})
</script>

<style lang="scss" scoped>
.text-input {
  background: #f8f9fa;

  textarea {
    min-height: 10rem;
  }

  .language-selector {
    padding: calc(0.375rem + 1px) 0.4em calc(0.375rem + 1px);
    //margin-top: calc(0.375rem + 1px);
    //position: absolute;
    //top: calc(0.375rem + 1px);
    //right: 0.75rem;

    //&.label {
    //top: calc(0.875rem + 25px);
    //}
  }

  &.textarea {
    .language-selector {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .language {
        flex-grow: 0;
      }
    }
  }

  &:not(.form-floating) label ~ .language-selector {
    //top: calc(0.875rem + 25px);
    padding-top: calc(0.875rem + 25px);
  }
}
</style>
