<template>
  <div v-if="kiosks" class="d-flex flex-column h-100">
    <custom-select
      class="mb-4"
      placeholder="Select Kiosks"
      :options="kiosks"
      :selected="checked"
      @selected="toggleKiosk($event.id)"
    >
      <template #default="{ option }">
        {{ option.title[0].value }}
        <template v-if="option.kiosk_id">{{ ` - ${option.kiosk_id}` }}</template>
      </template>
    </custom-select>
    <geo-selector v-if="kiosks" :kiosks="kiosks" :geo="geoRadius" @geo="setGeo" />
    <div class="d-flex align-items-center justify-content-end">
      <router-link
        :to="{ params: { tab: 'info', save: true } }"
        class="btn btn-success mb-4 gap-2"
        :class="{ disabled: hasChanges }"
      >
        <span class="material-icons">save</span>
        Save
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import axios from '@/api'

import project from '@/store/project'
import { pushChange, hasChanges as saveEnabled } from '@/store/changes'

import LocalisedValue from '@/components/misc/LocalisedValue.vue'
import GeoSelector from '@/components/inputs/GEOSelector.vue'
import CustomSelect from '@/components/inputs/CustomSelect.vue'

export default defineComponent({
  name: 'Announcement_Targeting',
  emits: ['loading'],
  components: {
    LocalisedValue,
    GeoSelector,
    CustomSelect,
  },
  props: {
    announcement: {
      type: Object as PropType<Announcement>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const kiosks = ref<Kiosk[]>()
    const geoRadius = ref<GeoRadius>({
      lat: props.announcement.lat,
      lng: props.announcement.lng,
      radius: props.announcement.radius,
    })
    const checked = ref<number[]>([...(props.announcement.kiosks ?? []).map((k) => k.kiosks_id)])

    const fetchData = async () => {
      const response = await axios.get(`/items/kiosks?filter[projects_id]=${project.value?.active?.id}`)
      kiosks.value = response?.data?.data
    }

    async function updateData() {
      emit('loading', true)
      try {
        await fetchData()
      } catch (e) {}
      emit('loading', false)
    }

    function isGeoEqual(geo1: GeoRadius, geo2: GeoRadius) {
      return geo1.lat === geo2.lat && geo1.lng === geo2.lng && geo1.radius === geo2.radius
    }

    function setGeo(geo: GeoRadius) {
      console.log(geo, geoRadius.value, props.announcement)
      if (!isGeoEqual(geo, props.announcement)) {
        geoRadius.value = geo
        pushChange('Announcements', props.announcement?.id, geo)
      } else {
        pushChange('Announcements', props.announcement?.id, { lat: undefined, lng: undefined, radius: undefined })
      }
    }

    function toggleKiosk(kisokId: number) {
      const index = checked.value.indexOf(kisokId)
      if (index !== -1) {
        checked.value.splice(index, 1)
      } else {
        checked.value.push(kisokId)
      }

      pushChange('Announcements', props.announcement?.id, { kiosks: checked.value.map((kId) => ({ kiosks_id: kId })) })
    }

    const hasChanges = computed(() => !saveEnabled())

    updateData()

    return { kiosks, setGeo, geoRadius, toggleKiosk, checked, hasChanges }
  },
})
</script>
